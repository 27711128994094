import React from 'react';
import { Button, Modal, Upload, message, Spin, Select } from 'antd';
import { useState, useEffect } from 'react';
import { CloseOutlined, PictureOutlined } from '@ant-design/icons';
import Axios from 'axios';
import toast from 'react-hot-toast';

const ImageModal = ({image, property_id, categoryId, imagesId}) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [uploadingCount, setUploadingCount] = useState(0);
    const [images, setImages] = useState([]);
    const [uploadedImageID, setUploadedImageID] = useState(imagesId ? [...imagesId] : []);
    const [saving, setSaving] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(categoryId || '');
    const [categories, setCategories] = useState([]);

    console.log(image,";;;;",imagesId);
    
    // Fetch categories when modal opens
    useEffect(() => {
        if (open) {
            fetchCategories();
        }
        // Initialize images if available
        if (image && Array.isArray(image)) {
            setImages(image);
        }
    }, [open, image]);
    
    const fetchCategories = async () => {
        try {
            const accessToken = localStorage.getItem('accessToken');
            const response = await Axios.get(
                'https://rentvip-apis.netsolutionindia.com/api/v1/category?type=PROPERTY',
                {
                    headers: {
                        'Authorization': accessToken
                    }
                }
            );
            
            if (response.data?.data?.categories) {
                setCategories(response.data.data.categories);
                if (!selectedCategory && response.data.data.categories.length > 0) {
                    setSelectedCategory(response.data.data.categories[0]._id);
                }
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const showModal = () => {
        setOpen(true);
    };

    // Resize image before uploading
    const resizeImage = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
                const img = new Image();
                img.src = event.target.result;
                img.onload = () => {
                    // Define max dimensions
                    const MAX_WIDTH = 1200;
                    const MAX_HEIGHT = 900;
                    
                    let width = img.width;
                    let height = img.height;
                    
                    // Calculate new dimensions while maintaining aspect ratio
                    if (width > height) {
                        if (width > MAX_WIDTH) {
                            height = Math.round(height * (MAX_WIDTH / width));
                            width = MAX_WIDTH;
                        }
                    } else {
                        if (height > MAX_HEIGHT) {
                            width = Math.round(width * (MAX_HEIGHT / height));
                            height = MAX_HEIGHT;
                        }
                    }
                    
                    const canvas = document.createElement('canvas');
                    canvas.width = width;
                    canvas.height = height;
                    
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, height);
                    
                    // Convert canvas to blob
                    canvas.toBlob((blob) => {
                        // Create a new file from the blob with same name/type
                        const resizedFile = new File([blob], file.name, {
                            type: file.type,
                            lastModified: Date.now()
                        });
                        resolve(resizedFile);
                    }, file.type);
                };
                img.onerror = (error) => {
                    reject(error);
                };
            };
            reader.onerror = (error) => {
                reject(error);
            };
        });
    };

    // Upload image to server
    const uploadImage = async (file) => {
        try {
            // Resize image before uploading
            const resizedFile = await resizeImage(file);
            
            // Create form data for API
            const formData = new FormData();
            formData.append('file', resizedFile);
            formData.append('type', '3');

            const accessToken = localStorage.getItem('accessToken');
            
            // Track upload count for loading state
            setUploadingCount(prev => prev + 1);
            
            // Make API call
            const response = await Axios.post(
                'https://rentvip-apis.netsolutionindia.com/api/v1/files/upload',
                formData,
                {
                    headers: {
                        'Authorization': accessToken,
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );
            
            // Prepare uploaded image object
            const uploadedImage = response.data.data.fileUrl;
            const uploadedImageID = response.data.data.fileId;
            
            // Add image to the state
            setImages(prev => [...prev, uploadedImage]);
            setUploadedImageID(prev => [...prev, uploadedImageID]);
            toast.success(`Image uploaded successfully`);
            return uploadedImage;
            
        } catch (error) {
            console.error('Error uploading image:', error);
            toast.error('Failed to upload image');
            return null;
        } finally {
            setUploadingCount(prev => prev - 1);
        }
    };

    // Handle multiple file uploads
    const handleImageUpload = async (file) => {
        setLoading(true);
        await uploadImage(file);
        setLoading(false);
        return false; // Prevent default upload behavior
    };

    // Handle multiple image deletion
    const handleImageDelete = async (index) => {
        try {
            setLoading(true);
            
            // Remove from current images array
            const updatedImages = [...images];
            updatedImages.splice(index, 1);
            setImages(updatedImages);
            
            // Also remove the corresponding ID
            const updatedImageIDs = [...uploadedImageID];
            updatedImageIDs.splice(index, 1);
            setUploadedImageID(updatedImageIDs);
            
            toast.success('Image removed');
        } catch (error) {
            console.error('Error removing image:', error);
            toast.error('Failed to remove image');
        } finally {
            setLoading(false);
        }
    };
    
    // Save changes to property
    const handleSaveChanges = async () => {
        
        if (images.length === 0) {
            toast.error('Please upload at least one image');
            return;
        }
        
        try {
            setSaving(true);
            
            const accessToken = localStorage.getItem('accessToken');
            
            // Prepare payload according to API requirements
            const payload = {
                images: uploadedImageID,
                property_id: property_id
            };
            
            // Make API call to update property
            const response = await Axios.patch(
                'https://rentvip-apis.netsolutionindia.com/api/v2/property',
                payload,
                {
                    headers: {
                        'Authorization': accessToken,
                    }
                }
            );
            console.log(response.data,"lllllll");
            
         
                toast.success('Property images updated successfully');
                setOpen(false);

        }catch (error) {
            console.error('Error updating property images:', error);
            toast.error(error.response.data.message || 'Failed to update property images');
        } finally {
            setSaving(false);
        }
    };

    return (
        <>
            <Button className='InactiveBtn' onClick={showModal}>
                Edit
            </Button>
            <Modal
                title="Edit Property Images"
                open={open}
                onCancel={() => setOpen(false)}
                width={1000}
                footer={[
                    <Button key="cancel" onClick={() => setOpen(false)}>
                        Cancel
                    </Button>,
                    <Button 
                        key="save" 
                        className='InactiveBtn' 
                        loading={saving}
                        onClick={handleSaveChanges}>
                        Save Changes
                    </Button>
                ]}
            >
                <Spin spinning={loading || uploadingCount > 0 || saving} tip={uploadingCount > 0 ? `Uploading ${uploadingCount} image(s)...` : saving ? 'Saving changes...' : 'Loading...'}>
                    
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '20px', marginBottom: '20px' }}>
                        {images?.map((img, index) => (
                            <div key={index} style={{ position: 'relative' }}>
                                <Button 
                                    className='InactiveBtn'
                                    icon={<CloseOutlined />} 
                                    style={{
                                        position: 'absolute',
                                        top: -15,
                                        right: -15,
                                        borderRadius: '50%',
                                    }}
                                    onClick={() => handleImageDelete(index)}
                                />
                                <img 
                                    src={img}
                                    alt={`Property ${index + 1}`} 
                                    style={{
                                        width: '100%',
                                        height: '200px',
                                        objectFit: 'cover',
                                        borderRadius: '8px'
                                    }}
                                />
                            </div>
                        ))}
                        <Upload.Dragger
                            multiple={true}
                            showUploadList={false}
                            beforeUpload={handleImageUpload}
                            style={{
                                height: '200px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                border: '2px dashed #d9d9d9',
                                borderRadius: '8px'
                            }}
                        >
                            <div>
                                <PictureOutlined style={{fontSize: '25px', color: 'grey'}} />
                                <p>Drag and drop or</p>
                                <Button type="link">Upload Photos</Button>
                            </div>
                        </Upload.Dragger>
                    </div>
                </Spin>
            </Modal>
        </>
    );
};

export default ImageModal;