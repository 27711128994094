import React, { useState, useEffect } from 'react';
import { Container, Form, Row, Col, Card, Button } from 'react-bootstrap';
import Select from 'react-select';
import Axios from 'axios';
import { withGoogleMap, GoogleMap, Marker, withScriptjs } from 'react-google-maps';
import { useDropzone } from 'react-dropzone';
import './AddHotel.css';
import { CiImageOn } from "react-icons/ci";
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TimePicker, Upload, Button as AntButton } from 'antd';
import moment from 'moment';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';

export const AddHotel = () => {
    const [formData, setFormData] = useState({
        country: 'United Arab Emirates',
        city: '',
        area: '',
        building: '',
        floorNumber: '',
        name: '',
        location: '',
        description: '',
        totalRooms: '',
        amenities: [],
        checkInTime: '',
        checkOutTime: '',
        pricePerNight: '',
        maxGuests: 2,
        bathroomPrivate: false,
        roomName: '',
        roomType: '',
        roomSize: '',
        hotelRating: '1',
        petAllowed: true,
        extraChargeForPet: false,
    });
    const [hotelId, setHotelId] = useState("");
    const [cities, setCities] = useState([]);
    const [areas, setAreas] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [cityData, setCityData] = useState(null);
    const [amenities, setAmenities] = useState([]);
    const [mapCenter, setMapCenter] = useState({
        lat: 25.2048, // Default to Dubai
        lng: 55.2708
    });

    const [activeTab, setActiveTab] = useState('propertyDetails');
    const [showBlankPage, setShowBlankPage] = useState(false);

    const [bedCounts, setBedCounts] = useState({});

    const [files, setFiles] = useState([]);

    // Add new state variables for room photos and amenities
    const [roomFiles, setRoomFiles] = useState([]);
    const [roomAmenities, setRoomAmenities] = useState([]);

    // Add this state for bed categories
    const [bedCategories, setBedCategories] = useState([]);

    // Add new state for storing multiple rooms
    const [rooms, setRooms] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            setFiles((prevFiles) => {
                const newFiles = acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                }));
                const totalFiles = prevFiles.length + newFiles.length;
                if (totalFiles > 20) {
                    // alert('You can only upload up to 4 images.');
                    toast.error('You can only upload up to 20 images.')
                    return prevFiles;
                }
                return [...prevFiles, ...newFiles];
            });
        }
    });

    // Modify the dropzone for room photos
    const { getRootProps: getRoomRootProps, getInputProps: getRoomInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            setRoomFiles((prevFiles) => {
                const newFiles = acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                }));
                const totalFiles = prevFiles.length + newFiles.length;
                if (totalFiles > 20) {
                    toast.error('You can only upload up to 20 images.')
                    return prevFiles;
                }
                return [...prevFiles, ...newFiles];
            });
        }
    });

    const removeFile = (file) => () => {
        setFiles(files.filter(f => f !== file));
    };

    const handleTabChange = (tab) => {
        if (activeTab === 'rooms') {
            setActiveTab('roomDetails');
        } else {
            setActiveTab(tab);
        }
    };

    // Define the Map component
    const MapComponent = withScriptjs(withGoogleMap(() => (
        <GoogleMap defaultZoom={13} center={mapCenter}>
            <Marker
                position={mapCenter}
                draggable={true}
                onDragEnd={(e) => {
                    setFormData(prev => ({
                        ...prev,
                        latitude: e.latLng.lat(),
                        longitude: e.latLng.lng()
                    }));
                }}
            />
        </GoogleMap>
    )));

    useEffect(() => {
        const getCities = async () => {
            try {
                const accessToken = localStorage.getItem('accessToken');
                const { data } = await Axios.get('https://rentvip-apis.netsolutionindia.com/api/v1/location/city', {
                    headers: { 'authorization': accessToken }
                });

                setCityData(data?.data || []);
                const formattedCities = data?.data?.map(city => ({
                    value: city._id,
                    label: city.name,
                    latitude: city.latitude,
                    longitude: city.longitude,
                    areaData: city.areaData
                })) || [];
                setCities(formattedCities);
            } catch (error) {
                console.error('Error fetching cities:', error);
            }
        };

        const listAmenities = async () => {
            try {
                const accessToken = localStorage.getItem('accessToken');
                const { data } = await Axios.get('https://rentvip-apis.netsolutionindia.com/api/v1/amenity', {
                    headers: { 'authorization': accessToken }
                });
                setAmenities(data?.data);
            } catch (error) {
                console.error('Error fetching amenities:', error);
            }
        };

        const fetchCategories = async () => {
            try {
                const accessToken = localStorage.getItem('accessToken');
                const { data } = await Axios.get('https://rentvip-apis.netsolutionindia.com/api/v1/category?type=PROPERTY', {
                    headers: { 'authorization': accessToken }
                });
                const hotelCategory = data?.data?.categories?.find(
                    (category) => category.name.toLowerCase() === 'hotel'
                );
                setHotelId(hotelCategory?._id);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
        const fetchBedCategories = async () => {
            try {
                const accessToken = localStorage.getItem('accessToken');
                const response = await Axios.get(
                    'https://rentvip-apis.netsolutionindia.com/api/v1/property/beds/category',
                    {
                        headers: {
                            'authorization': accessToken,
                        }
                    }
                );
                setBedCategories(response.data?.data || []);
            } catch (error) {
                console.error('Error fetching bed categories:', error);
                toast.error('Failed to fetch bed categories');
            }
        };

        fetchBedCategories();
        getCities();
        listAmenities();
        fetchCategories();
    }, [dispatch]);

    const handleCityChange = (selectedOption) => {
        setFormData(prev => ({
            ...prev,
            city: selectedOption?.value || '',
            area: '',
            building: '',
            latitude: selectedCity?.latitude || 0,
            longitude: selectedCity?.longitude || 0
        }));

        const selectedCity = cities.find(city => city.value === selectedOption?.value);

        if (selectedCity) {
            setMapCenter({ lat: selectedCity.latitude, lng: selectedCity.longitude });
            setAreas(selectedCity.areaData.map(area => ({
                value: area._id,
                label: area.name,
                latitude: area.latitude,
                longitude: area.longitude,
                buildingData: area.buildingData
            })));
        } else {
            setAreas([]);
        }
        setBuildings([]);
    };

    const handleAreaChange = (selectedOption) => {
        setFormData(prev => ({
            ...prev,
            area: selectedOption?.value || '',
            building: '',
            latitude: selectedArea?.latitude || prev.latitude,
            longitude: selectedArea?.longitude || prev.longitude
        }));

        const selectedArea = areas.find(area => area.value === selectedOption?.value);
        if (selectedArea) {
            setMapCenter({ lat: selectedArea.latitude, lng: selectedArea.longitude });
            setBuildings(selectedArea.buildingData.map(building => ({
                value: building._id,
                label: building.name,
                latitude: building.latitude,
                longitude: building.longitude
            })));
        } else {
            setBuildings([]);
        }
    };

    const handleBuildingChange = (selectedOption) => {
        setFormData(prev => ({
            ...prev,
            building: selectedOption?.value || '',
            latitude: selectedBuilding?.latitude || prev.latitude,
            longitude: selectedBuilding?.longitude || prev.longitude
        }));

        const selectedBuilding = buildings.find(building => building.value === selectedOption?.value);
        if (selectedBuilding) {
            setMapCenter({ lat: selectedBuilding.latitude, lng: selectedBuilding.longitude });
        }
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            borderRadius: '0.375rem',
            border: '1px solid #ced4da',
            minHeight: '40px'
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#6c757d'
        })
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const handleContinue = () => {
        // Validate required fields
        if (!formData.city) {
            toast.error('Please select a city');
            return;
        }
        if (!formData.area) {
            toast.error('Please select an area');
            return;
        }
        if (!formData.building) {
            toast.error('Please select a building');
            return;
        }
        setActiveTab('propertyDetails');
        setShowBlankPage(true);
    };

    const handleBack = () => {
        setShowBlankPage(false);
    };

    const handleBedCountChange = (bedId, action) => {
        setBedCounts(prev => {
            const currentCount = prev[bedId] || 0;
            let newCount;
            if (action === 'increment') {
                newCount = currentCount + 1;
            } else if (action === 'decrement') {
                newCount = Math.max(0, currentCount - 1); // Prevent negative numbers
            }
            return {
                ...prev,
                [bedId]: newCount
            };
        });
    };

    const handleCheckboxChange = (e, amenity) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setFormData(prev => ({
                ...prev,
                amenities: [...prev.amenities, {
                    _id: amenity._id,
                    name: amenity.title,
                    isSpecial: false
                }]
            }));
        } else {
            setFormData(prev => ({
                ...prev,
                amenities: prev.amenities.filter(a => a._id !== amenity._id)
            }));
        }
    };

    // Add room amenities handler
    const handleRoomAmenitiesChange = (e, amenity) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setRoomAmenities(prev => [...prev, {
                _id: amenity._id,
                name: amenity.title,
                isSpecial: false
            }]);
        } else {
            setRoomAmenities(prev =>
                prev.filter(a => a._id !== amenity._id)
            );
        }
    };

    const handleRoomDetailsChange = (field, value) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    // Add function to upload file and get link
    const uploadFile = async (file) => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('type', '3');

            const accessToken = localStorage.getItem('accessToken');
            const response = await Axios.post(
                'https://rentvip-apis.netsolutionindia.com/api/v1/files/upload',
                formData,
                {
                    headers: {
                        'authorization': accessToken,
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );
            // Update to use the correct path: response.data.data.fileUrl
            return {
                fileId: response.data.data.fileId,
                fileUrl: response.data.data.fileUrl
            };
        } catch (error) {
            console.error('Error uploading file:', error);
            toast.error('Error uploading file');
            return null;
        }
    };

    // Modify the handleAddRoom function to stay on the same tab
    const handleAddRoom = () => {
        // Validate required fields
        if (!formData.roomName || !formData.roomType) {
            toast.error('Room name and type are required');
            return;
        }
        
        // Add validation for minimum 6 photos
        if (roomFiles.length < 6) {
            toast.error('Minimum 6 photos are required for each room');
            return;
        }

        // Create room object from current form data
        const newRoom = {
            roomName: formData.roomName,
            roomType: String(formData.roomType || ''),
            amenities: roomAmenities.map(amenity => ({
                _id: amenity.id || amenity._id,
                isSpecial: Boolean(amenity.isSpecial)
            })).filter(amenity => amenity._id),
            numberOfRooms: parseInt(formData.totalRooms) || 1,
            maxAdults: parseInt(formData.maxAdults) || 2,
            maxChildren: parseInt(formData.maxChildren) || 0,
            maxGuests: parseInt(formData.maxGuests) || 2,
            roomSize: formData.roomSize || "00",
            price: parseFloat(formData.pricePerNight) || 0,
            isSmokingAllowed: formData.isSmokingAllowed || false,
            isBathroomPrivate: formData.bathroomPrivate || false,
            bedOptions: Object.entries(bedCounts).map(([bedType, count]) => ({
                bedType,
                count: parseInt(count) || 1
            })),
            roomPhotos: [], // Will be populated with uploaded IDs
            tempRoomPhotos: roomFiles // Temporarily store files for preview
        };

        // Add to rooms array
        setRooms([...rooms, newRoom]);

        // Reset room form fields for next room
        setFormData(prev => ({
            ...prev,
            roomName: '',
            roomType: '',
            totalRooms: '',
            maxGuests: 2,
            bathroomPrivate: false,
            roomSize: '',
            pricePerNight: '',
        }));
        setBedCounts({});
        setRoomAmenities([]);
        setRoomFiles([]);

        // Show success message
        toast.success('Room saved! You can add another room or continue to save the hotel.');

        // Optionally show a temporary indicator that the room was added
        const roomCountIndicator = document.getElementById('roomCountIndicator');
        if (roomCountIndicator) {
            roomCountIndicator.classList.add('blink');
            setTimeout(() => {
                roomCountIndicator.classList.remove('blink');
            }, 1500);
        }
    };

    // Add function to remove a room from the array
    const handleRemoveRoom = (index) => {
        const updatedRooms = [...rooms];
        updatedRooms.splice(index, 1);
        setRooms(updatedRooms);
    };

    // Add function to edit a room
    const handleEditRoom = (index) => {
        const roomToEdit = rooms[index];

        // Populate form with room data
        setFormData(prev => ({
            ...prev,
            roomName: roomToEdit.roomName,
            roomType: roomToEdit.roomType,
            totalRooms: roomToEdit.numberOfRooms,
            maxGuests: roomToEdit.maxGuests,
            bathroomPrivate: roomToEdit.isBathroomPrivate,
            roomSize: roomToEdit.roomSize,
            pricePerNight: roomToEdit.price,
            maxAdults: roomToEdit.maxAdults,
            maxChildren: roomToEdit.maxChildren,
            isSmokingAllowed: roomToEdit.isSmokingAllowed
        }));

        // Set bed counts
        const newBedCounts = {};
        roomToEdit.bedOptions.forEach(bed => {
            newBedCounts[bed.bedType] = bed.count;
        });
        setBedCounts(newBedCounts);

        // Set room amenities
        setRoomAmenities(roomToEdit.amenities);

        // Set room files
        setRoomFiles(roomToEdit.tempRoomPhotos || []);

        // Remove the room from the array
        handleRemoveRoom(index);

        // Switch to rooms tab for editing
        setActiveTab('rooms');
    };

    // Replace the three separate handler functions with a single generic handler
    const handleRadioChange = (field, value) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    // Modify handleSave to handle multiple rooms
    const handleSave = async () => {
        try {
            setIsLoading(true); // Start loading
            
            // Upload property photos
            const propertyPhotoUrls = await Promise.all(
                files.map(async (file) => {
                    const uploadResponse = await uploadFile(file);
                    return uploadResponse?.fileId;
                })
            );

            // Create array to hold all rooms with uploaded photos
            const hotelRoomsData = [];

            // For each room in the rooms array, upload photos and add to hotelRoomsData
            for (const room of rooms) {
                const roomPhotoUrls = [];

                // Upload room photos if they exist
                if (room.tempRoomPhotos && room.tempRoomPhotos.length > 0) {
                    for (const file of room.tempRoomPhotos) {
                        const uploadResponse = await uploadFile(file);
                        if (uploadResponse?.fileId) {
                            roomPhotoUrls.push(uploadResponse.fileId);
                        }
                    }
                }

                // Add room with uploaded photo IDs to hotelRoomsData
                hotelRoomsData.push({
                    ...room,
                    roomPhotos: roomPhotoUrls
                });

                // Remove tempRoomPhotos as it's not needed for API
                delete hotelRoomsData[hotelRoomsData.length - 1].tempRoomPhotos;
            }

            // If user is currently editing a room, add that too
            if (formData.roomName && activeTab === 'rooms') {
                // Upload current room photos
                const currentRoomPhotoUrls = await Promise.all(
                    roomFiles.map(async (file) => {
                        const uploadResponse = await uploadFile(file);
                        return uploadResponse?.fileId;
                    })
                );

                // Format amenities for current room
                const formattedRoomAmenities = roomAmenities.map(amenity => ({
                    _id: amenity.id || amenity._id,
                    isSpecial: Boolean(amenity.isSpecial)
                })).filter(amenity => amenity._id);

                // Add current room to hotelRoomsData
                hotelRoomsData.push({
                    roomName: formData.roomName,
                    roomType: String(formData.roomType || ''),
                    amenities: formattedRoomAmenities,
                    numberOfRooms: parseInt(formData.totalRooms) || 1,
                    maxAdults: parseInt(formData.maxAdults) || 2,
                    maxChildren: parseInt(formData.maxChildren) || 0,
                    maxGuests: parseInt(formData.maxGuests) || 2,
                    roomSize: formData.roomSize || "00",
                    price: parseFloat(formData.pricePerNight) || 0,
                    isSmokingAllowed: formData.isSmokingAllowed || false,
                    isBathroomPrivate: formData.bathroomPrivate || false,
                    bedOptions: Object.entries(bedCounts).map(([bedType, count]) => ({
                        bedType,
                        count: parseInt(count) || 1
                    })),
                    roomPhotos: currentRoomPhotoUrls.filter(url => url)
                });
            }

            // Format amenities for API - ensure _id exists
            const formattedHotelAmenities = formData.amenities.map(amenity => ({
                _id: amenity.id || amenity._id,
                isSpecial: Boolean(amenity.isSpecial)
            })).filter(amenity => amenity._id);
            // Prepare API payload with all rooms
            const apiPayload = {
                city_id: formData.city,
                areaId: formData.area,
                buildingId: formData.building,
                category_id: hotelId,
                amenities: formattedHotelAmenities,
                name: formData.name && formData.name.trim() !== '' ? formData.name.trim() : "New Hotel",
                description: formData.description && formData.description.trim() !== '' ? formData.description.trim() : 'No description provided',
                latitude: formData.latitude || mapCenter.lat,
                longitude: formData.longitude || mapCenter.lng,
                images: propertyPhotoUrls.filter(url => url),
                currency: formData.currency || 'AED',
                hotelRooms: hotelRoomsData,
                hotelRating: formData.hotelRating,
                petAllowed: formData.petAllowed,
                extraChargeForPet: formData.extraChargeForPet,
            };

            console.log('API Payload with multiple rooms:', apiPayload);

            const accessToken = localStorage.getItem('accessToken');
            const response = await Axios.post(
                'https://rentvip-apis.netsolutionindia.com/api/v1/hotel/add',
                apiPayload,
                {
                    headers: {
                        'Authorization': accessToken,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.data.statusCode === 200) {
                toast.success('Hotel with multiple rooms added successfully!');
                // Navigate to the property page after successful addition
                setTimeout(() => {
                    navigate('/property');
                }, 1500); // Short delay to allow user to see the success message
            } else {
                toast.error('Failed to add hotel');
            }
        } catch (error) {
            console.error('Error saving data:', error);
            const errorMessage = error.response?.data?.error || error.response?.data?.message || 'Error saving data';
            toast.error(errorMessage);
        } finally {
            setIsLoading(false); // Stop loading regardless of success or failure
        }
    };

    // First, let's create a combined function to save room and navigate
    const handleSaveAndViewRooms = () => {
        // Validate required fields before saving
        if (!formData.roomName || !formData.roomType) {
            toast.error('Room name and type are required');
            return;
        }
        
        // Add validation for minimum 6 photos
        if (roomFiles.length < 6) {
            toast.error('Minimum 6 photos are required for each room');
            return;
        }

        // Save the current room data
        handleAddRoom();

        // Navigate to room details tab
        setActiveTab('roomDetails');
    };

    return (
        <>
            <Container className='p-4 bg-white rounded-3'>
                <div className="mb-4">
                    <div className="d-flex gap-4">
                        <Button
                            onClick={() => {
                                // Always navigate to property details when clicked
                                if (activeTab !== 'propertyDetails') {
                                    setActiveTab('propertyDetails');
                                    // If we were in roomDetails or rooms, ensure we show the second property details page
                                    if (activeTab === 'roomDetails' || activeTab === 'rooms') {
                                        setShowBlankPage(true);
                                    }
                                }
                            }}
                            className={`${activeTab === 'propertyDetails' ? 'activeBtn' : 'disabledBtn'}`}
                        >
                            Property Details
                        </Button>
                        <Button
                            onClick={() => activeTab === 'propertyDetails' ? null : handleTabChange('roomDetails')}
                            className={`${activeTab === 'rooms' || activeTab === "roomDetails" ? 'activeBtn' : 'disabledBtn'}`}
                            style={{
                                backgroundColor: activeTab === 'propertyDetails' ? '#f2f2f2' : '',
                                cursor: activeTab === 'propertyDetails' ? 'not-allowed' : 'pointer',
                                opacity: activeTab === 'propertyDetails' ? '0.7' : '1',
                                pointerEvents: activeTab === 'propertyDetails' ? 'none' : 'auto'
                            }}
                        >
                            Rooms
                            {rooms.length > 0 && (
                                <span
                                    id="roomCountIndicator"
                                    className="ms-2 badge bg-light text-dark"
                                    style={{ borderRadius: '50%' }}
                                >
                                    {rooms.length}
                                </span>
                            )}
                        </Button>
                    </div>
                </div>

                {activeTab === 'propertyDetails' && !showBlankPage && (
                    <Row>
                        <Col md={6}>
                            <h4 className="mb-4">Where is your property located?</h4>

                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-4">
                                    <Form.Label className="text-muted">Country</Form.Label>
                                    <Select
                                        styles={customStyles}
                                        value={{ value: 'UAE', label: 'United Arab Emirates' }}
                                        options={[{ value: 'UAE', label: 'United Arab Emirates' }]}
                                        isSearchable={false}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label className="text-muted">Select City</Form.Label>
                                    <Select
                                        styles={customStyles}
                                        value={cities.find(city => city.value === formData.city)}
                                        onChange={handleCityChange}
                                        options={cities}
                                        placeholder="Select a city"
                                        isClearable
                                    />
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label className="text-muted">Select Area</Form.Label>
                                    <Select
                                        styles={customStyles}
                                        value={areas.find(area => area.value === formData.area)}
                                        onChange={handleAreaChange}
                                        options={areas}
                                        placeholder="Select an area"
                                        isClearable
                                        isDisabled={!formData.city}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label className="text-muted">Select Building</Form.Label>
                                    <Select
                                        styles={customStyles}
                                        value={buildings.find(building => building.value === formData.building)}
                                        onChange={handleBuildingChange}
                                        options={buildings}
                                        placeholder="Select a building"
                                        isClearable
                                        isDisabled={!formData.area}
                                    />
                                </Form.Group>
                            </Form>
                        </Col>

                        <Col md={6}>
                            <h4 className="mb-4">Pin the location</h4>
                            <div className="border rounded">
                                <MapComponent
                                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyApA3S2uvw7wXWzQ8T0ueJZCDrSAiyhqtg&v=3.exp&libraries=geometry,drawing,places`}
                                    loadingElement={<div style={{ height: `100%` }} />}
                                    containerElement={<div style={{ height: `500px` }} />}
                                    mapElement={<div style={{ height: `100%` }} />}
                                />
                            </div>
                        </Col>
                    </Row>
                )}

                {activeTab === 'propertyDetails' && showBlankPage && (
                    <Row>
                        <Col md={6}>
                            <Form>
                                <Form.Group className="mb-4">
                                    <Form.Label>Enter Hotel Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Hotel Name"
                                        value={formData.name}
                                        onChange={(e) => {
                                            const value = e.target.value;  // Capture the value immediately
                                            setFormData(prev => ({ ...prev, name: value }));
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label>What can guests access at your hotel?</Form.Label>
                                    <input
                                        type="text"
                                        className="form-control mb-3"
                                        value={formData.amenities.map(a => a.name).join(', ')}
                                        readOnly
                                        placeholder="Select Amenities"
                                    />
                                    <div className="border rounded p-3">
                                        <div className="d-flex flex-wrap">
                                            {amenities.map((amenity, index) => (
                                                <div key={amenity?._id} className="form-check col-12 col-sm-6 col-lg-4 mb-2">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={amenity?._id}
                                                        id={`amenity-${amenity?._id}`}
                                                        onChange={(e) => handleCheckboxChange(e, amenity)}
                                                        checked={formData.amenities.some(a => a._id === amenity?._id)}
                                                    />
                                                    <small htmlFor={`amenity-${amenity?._id}`} className="text-truncate d-inline-block" style={{ maxWidth: '100%' }}>
                                                        {amenity?.title}
                                                    </small>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Form.Group>
                            </Form>
                        </Col>

                        <Col md={6}>
                            <Form>
                                <Form.Group className="mb-4">
                                    <Form.Label>Check In Time</Form.Label>
                                    <div>
                                        <TimePicker
                                            format="HH:mm"
                                            value={formData.checkInTime ? moment(formData.checkInTime, 'HH:mm') : null}
                                            onChange={(time) => {
                                                const timeString = time ? time.format('HH:mm') : '';
                                                setFormData(prev => ({ ...prev, checkInTime: timeString }));
                                            }}
                                            style={{ width: '100%', height: "38px" }}
                                            placeholder="Select check-in time"
                                        />
                                    </div>
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label>Check Out Time</Form.Label>
                                    <div>
                                        <TimePicker
                                            format="HH:mm"
                                            value={formData.checkOutTime ? moment(formData.checkOutTime, 'HH:mm') : null}
                                            onChange={(time) => {
                                                const timeString = time ? time.format('HH:mm') : '';
                                                setFormData(prev => ({ ...prev, checkOutTime: timeString }));
                                            }}
                                            style={{ width: '100%', height: "38px" }}
                                            placeholder="Select check-out time"
                                        />
                                    </div>
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label>Star Rating of your hotel</Form.Label>
                                    <div>
                                        {[
                                            { id: 'rating-0', value: '0', label: 'Standard Hotel' },
                                            { id: 'rating-6', value: '6', label: 'Hotel Apartment' },
                                            { id: 'rating-1', value: '1', label: '1 Star' },
                                            { id: 'rating-2', value: '2', label: '2 Stars' },
                                            { id: 'rating-3', value: '3', label: '3 Stars' },
                                            { id: 'rating-4', value: '4', label: '4 Stars' },
                                            { id: 'rating-5', value: '5', label: '5 Stars' }
                                        ].map(option => (
                                            <Form.Check
                                                key={option.id}
                                                type="radio"
                                                label={option.label}
                                                name="starRating"
                                                id={option.id}
                                                checked={formData.hotelRating === option.value}
                                                onChange={() => handleRadioChange('hotelRating', option.value)}
                                                inline
                                            />))}
                                    </div>
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label>Are pets allowed?</Form.Label>
                                    <div>
                                        {[
                                            { id: 'pets-yes', value: true, label: 'Yes' },
                                            { id: 'pets-no', value: false, label: 'No' },
                                            // { id: 'pets-request', value: 'request', label: 'Upon Request' }
                                        ].map(option => (
                                            <Form.Check
                                                key={option.id}
                                                type="radio"
                                                label={option.label}
                                                name="petsAllowed"
                                                id={option.id}
                                                checked={formData.petAllowed === option.value}
                                                onChange={() => handleRadioChange('petAllowed', option.value)}
                                                inline
                                            />
                                        ))}
                                    </div>
                                </Form.Group>
                                {formData.petAllowed && (
                                    <Form.Group className="mb-4">
                                        <Form.Label>Is there an extra charge for pets?</Form.Label>
                                        <div>
                                            {[
                                                { id: 'pet-charge-no', value: false, label: 'Pets can stay for free' },
                                                { id: 'pet-charge-yes', value: true, label: 'Additional fees may apply' }
                                            ].map(option => (
                                                <Form.Check
                                                    key={option.id}
                                                    type="radio"
                                                    label={option.label}
                                                    name="petCharge"
                                                    id={option.id}
                                                    checked={formData.extraChargeForPet === option.value}
                                                    onChange={() => handleRadioChange('extraChargeForPet', option.value)}
                                                    inline
                                                />
                                            ))}
                                        </div>
                                    </Form.Group>)}
                                <Form.Group className="mb-4">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={6}
                                        placeholder="Enter Description"
                                        value={formData.description}
                                        onChange={(e) => {
                                            const value = e.target.value;  // Capture the value immediately
                                            setFormData(prev => ({ ...prev, description: value }));
                                        }}
                                    />
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-4">
                                <Form.Label>Hotel Photos</Form.Label>
                                <Upload
                                    listType="picture-card"
                                    fileList={files.map((file, index) => ({
                                        uid: index,
                                        name: file.name || `image-${index}`,
                                        status: 'done',
                                        url: file.preview,
                                        originFileObj: file
                                    }))}
                                    onPreview={(file) => {
                                        const url = file.url || file.preview;
                                        window.open(url, '_blank');
                                    }}
                                    onRemove={(file) => {
                                        const index = file.uid;
                                        const newFiles = [...files];
                                        newFiles.splice(index, 1);
                                        setFiles(newFiles);
                                    }}
                                    beforeUpload={(file) => {
                                        if (files.length >= 20) {
                                            toast.error('You can only upload up to 20 images.');
                                            return false;
                                        }
                                        const newFile = Object.assign(file, {
                                            preview: URL.createObjectURL(file)
                                        });
                                        setFiles(prev => [...prev, newFile]);
                                        return false; // Prevent default upload behavior
                                    }}
                                    accept="image/*"
                                    multiple
                                >
                                    {files.length < 20 && (
                                        <div>
                                            <PlusOutlined />
                                            <div style={{ marginTop: 8 }}>Upload</div>
                                        </div>
                                    )}
                                </Upload>
                            </Form.Group>
                        </Col>
                    </Row>
                )}

                {activeTab === 'rooms' && (
                    <Row>
                        {rooms.length > 0 && (
                            <Col md={12} className="mb-3">
                                <div className="alert alert-success" role="alert">
                                    <strong>{rooms.length} room{rooms.length > 1 ? 's' : ''} added</strong>.
                                    You can <a href="#" onClick={(e) => { e.preventDefault(); setActiveTab('roomDetails'); }}>
                                        view all rooms
                                    </a> or continue adding more.
                                </div>
                            </Col>
                        )}

                        <Col md={6}>
                            <Form>
                                <Form.Group className="mb-4">
                                    <Form.Label>Name of the room</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Double Room with Runway View"
                                        value={formData.roomName}
                                        onChange={(e) => handleRoomDetailsChange('roomName', e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label>What type of room is this?</Form.Label>
                                    <Form.Select
                                        value={formData.roomType || ""}
                                        onChange={(e) => {
                                            if (e && e.target) {
                                                const newValue = e.target.value;
                                                console.log("Room type selected:", newValue);
                                                handleRoomDetailsChange('roomType', newValue);
                                            }
                                        }}
                                        required
                                    >
                                        <option value="">Select Room Type</option>
                                        <option value="SINGLE">Single Room</option>
                                        <option value="DOUBLE">Double Room</option>
                                        <option value="SUITE">Suite</option>
                                        <option value="DELUXE">Deluxe Room</option>
                                        <option value="EXECUTIVE">Executive Room</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label>No. of Rooms (Of this type)</Form.Label>
                                    <Form.Control 
                                        type="number" 
                                        value={formData.totalRooms || ''} 
                                        onChange={(e) => handleRoomDetailsChange('totalRooms', e.target.value)}
                                        min="1"
                                        placeholder="Enter number of rooms" 
                                    />
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label>What bed options are available in this room?</Form.Label>
                                    <div>
                                        {bedCategories.map((bed) => (
                                            <div key={bed._id} className="d-flex justify-content-between align-items-center mb-2">
                                                <span>
                                                    <strong className="fs-4">
                                                        <img
                                                            src={bed.icon}
                                                            alt={bed.name}
                                                            style={{ width: '24px', height: '24px' }}
                                                            className="me-2"
                                                        />
                                                    </strong>
                                                    <small>{bed.name}</small>
                                                </span>
                                                <div className="d-flex align-items-center border rounded">
                                                    <Button
                                                        variant="outline-secondary"
                                                        className='border-0 fs-4'
                                                        size="sm"
                                                        onClick={() => handleBedCountChange(bed._id, 'decrement')}
                                                        disabled={!bedCounts[bed._id]} // Disable if count is 0
                                                        onMouseEnter={(e) => {
                                                            if (bedCounts[bed._id]) { // Only change style if button is enabled
                                                                e.target.style.color = '#f28c28';
                                                                e.target.style.backgroundColor = '#fff';
                                                            }
                                                        }}
                                                        onMouseLeave={(e) => {
                                                            if (bedCounts[bed._id]) {
                                                                e.target.style.color = '';
                                                                e.target.style.backgroundColor = '';
                                                            }
                                                        }}
                                                    >
                                                        -
                                                    </Button>
                                                    <span className="mx-2 text-center" style={{ width: '25px' }}>
                                                        {bedCounts[bed._id] || 0}
                                                    </span>
                                                    <Button
                                                        variant="outline-secondary"
                                                        className='border-0 fs-4'
                                                        size="sm"
                                                        onClick={() => handleBedCountChange(bed._id, 'increment')}
                                                        onMouseEnter={(e) => {
                                                            e.target.style.color = '#f28c28';
                                                            e.target.style.backgroundColor = '#fff';
                                                        }}
                                                        onMouseLeave={(e) => {
                                                            e.target.style.color = '';
                                                            e.target.style.backgroundColor = '';
                                                        }}
                                                    >
                                                        +
                                                    </Button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    {/* <a href="#" className="text-warning">See more bed options</a> */}
                                </Form.Group>

                            </Form>
                        </Col>

                        <Col md={6}>
                            <Form>
                                <Form.Group className="mb-4">
                                    <Form.Label>Maximum Adults</Form.Label>
                                    <Select
                                        styles={customStyles}
                                        options={[
                                            { value: 1, label: '1' },
                                            { value: 2, label: '2' },
                                            { value: 3, label: '3' },
                                        ]}
                                        defaultValue={{ value: 2, label: '2' }}
                                        placeholder="Select maximum adults"
                                    />
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label>Maximum Children</Form.Label>
                                    <Select
                                        styles={customStyles}
                                        options={[
                                            { value: 0, label: '0' },
                                            { value: 1, label: '1' },
                                            { value: 2, label: '2' },
                                        ]}
                                        defaultValue={{ value: 1, label: '1' }}
                                        placeholder="Select maximum children"
                                    />
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label>Maximum Guests</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={formData.maxGuests}
                                        onChange={(e) => handleRoomDetailsChange('maxGuests', e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label>What is the room size?</Form.Label>
                                    <div className="d-flex">
                                        <Form.Control
                                            type="text"
                                            value={formData.roomSize || ""}
                                            onChange={(e) => {
                                                if (e && e.target) {
                                                    const value = e.target.value;
                                                    console.log("Room size changed:", value);
                                                    handleRoomDetailsChange('roomSize', value);
                                                }
                                            }}
                                            className="me-2"
                                            style={{ flex: 1 }}
                                        />
                                        <Select
                                            styles={{
                                                ...customStyles,
                                                container: (provided) => ({
                                                    ...provided,
                                                    flex: 1
                                                })
                                            }}
                                            options={[
                                                { value: 'square meters', label: 'square meters' },
                                            ]}
                                            defaultValue={{ value: 'square meters', label: 'square meters' }}
                                        />
                                    </div>
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label>Set the price</Form.Label>
                                    <div className="d-flex">
                                        <Form.Control
                                            type="text"
                                            value={formData.pricePerNight}
                                            onChange={(e) => handleRoomDetailsChange('pricePerNight', e.target.value)}
                                            className="me-2"
                                            style={{ flex: 1 }}
                                        />
                                        <Select
                                            styles={{
                                                ...customStyles,
                                                container: (provided) => ({
                                                    ...provided,
                                                    flex: 1
                                                })
                                            }}
                                            options={[
                                                { value: 'per night', label: 'per night' },
                                            ]}
                                            defaultValue={{ value: 'per night', label: 'per night' }}
                                        />
                                    </div>
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label>Is smoking allowed in this room?</Form.Label>
                                    <div>
                                        <Form.Check
                                            type="radio"
                                            label="Yes"
                                            name="smoking"
                                            id="smokingYes"
                                            inline
                                            className="custom-radio"
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="No"
                                            name="smoking"
                                            id="smokingNo"
                                            inline
                                            className="custom-radio"
                                        />
                                    </div>
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label>Is the bathroom private?</Form.Label>
                                    <div>
                                        <Form.Check
                                            type="radio"
                                            label="Yes"
                                            name="bathroom"
                                            id="bathroomYes"
                                            checked={formData.bathroomPrivate}
                                            onChange={(e) => handleRoomDetailsChange('bathroomPrivate', true)}
                                            inline
                                            className="custom-radio"
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="No"
                                            name="bathroom"
                                            id="bathroomNo"
                                            inline
                                            className="custom-radio"
                                        />
                                    </div>
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-4">
                                <Form.Label>What amenities are available in this room?</Form.Label>
                                <input
                                    type="text"
                                    className="form-control mb-3"
                                    value={roomAmenities.map(a => a.name).join(', ')}
                                    readOnly
                                    placeholder="Select Room Amenities"
                                />
                                <div className="border rounded p-3">
                                    <div className="d-flex flex-wrap">
                                        {amenities.map((amenity, index) => (
                                            <div key={amenity?._id} className="form-check col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={amenity?._id}
                                                    id={`room-amenity-${amenity?._id}`}
                                                    onChange={(e) => handleRoomAmenitiesChange(e, amenity)}
                                                    checked={roomAmenities.some(a => a._id === amenity?._id)}
                                                />
                                                <small htmlFor={`room-amenity-${amenity?._id}`}>
                                                    {amenity?.title}
                                                </small>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-4">
                                <Form.Label>Room Photos</Form.Label>
                                <Upload
                                    listType="picture-card"
                                    fileList={roomFiles.map((file, index) => ({
                                        uid: index,
                                        name: file.name || `image-${index}`,
                                        status: 'done',
                                        url: file.preview,
                                        originFileObj: file
                                    }))}
                                    onPreview={(file) => {
                                        const url = file.url || file.preview;
                                        window.open(url, '_blank');
                                    }}
                                    onRemove={(file) => {
                                        const index = file.uid;
                                        const newFiles = [...roomFiles];
                                        newFiles.splice(index, 1);
                                        setRoomFiles(newFiles);
                                    }}
                                    beforeUpload={(file) => {
                                        if (roomFiles.length >= 20) {
                                            toast.error('You can only upload up to 20 images.');
                                            return false;
                                        }
                                        const newFile = Object.assign(file, {
                                            preview: URL.createObjectURL(file)
                                        });
                                        setRoomFiles(prev => [...prev, newFile]);
                                        return false; // Prevent default upload behavior
                                    }}
                                    accept="image/*"
                                    multiple
                                >
                                    {roomFiles.length < 20 && (
                                        <div>
                                            <PlusOutlined />
                                            <div style={{ marginTop: 8 }}>Upload</div>
                                        </div>
                                    )}
                                </Upload>
                            </Form.Group>
                        </Col>
                    </Row>
                )}

{activeTab === 'roomDetails' && (
                    <Col md={12}>
                        <div className="mb-3">
                            <h4 className="mb-3">Room Summary</h4>
                            <p className="text-muted">
                                {rooms.length > 0
                                    ? `You have added ${rooms.length} room${rooms.length > 1 ? 's' : ''} to this property.`
                                    : 'No rooms added yet. Add rooms to complete your property listing.'}
                            </p>
                        </div>

                        {rooms.length > 0 ? (
                            rooms.map((room, index) => (
                                <Row key={index} className="border rounded p-3 mb-3" style={{ borderColor: '#f28c28' }}>
                                    <Col xs={12} md={2} className="text-center mb-3 mb-md-0">
                                        {room.tempRoomPhotos && room.tempRoomPhotos.length > 0 ? (
                                            <img
                                                src={room.tempRoomPhotos[0].preview}
                                                alt={room.roomName}
                                                className="img-fluid rounded"
                                                style={{ maxHeight: '150px', objectFit: 'cover' }}
                                            />
                                        ) : (
                                            <div className="bg-light rounded p-3">No Image</div>
                                        )}
                                    </Col>
                                    <Col xs={12} md={10}>
                                        <div className="d-flex justify-content-between align-items-center mb-3 flex-wrap">
                                            <h5 className="text-truncate" style={{ color: '#f28c28', marginBottom: 0 }}>
                                                {room.roomName || 'Unnamed Room'}
                                            </h5>

                                            {/* Buttons - Stack vertically on small screens */}
                                            <div className="mt-2 mt-md-0 flex-wrap d-none d-md-flex">
                                                <Button
                                                    className="me-2 mb-2 mb-md-0"
                                                    style={{ backgroundColor: '#fff', color: '#f28c28', border: '1px solid #f28c28' }}
                                                    size="sm"
                                                    onClick={() => handleRemoveRoom(index)}
                                                >
                                                    Delete
                                                </Button>
                                                <Button
                                                    style={{ backgroundColor: '#f28c28', color: '#fff', border: '1px solid #f28c28' }}
                                                    size="sm"
                                                    onClick={() => handleEditRoom(index)}
                                                >
                                                    Edit
                                                </Button>
                                            </div>
                                        </div>

                                        {/* Stats Cards - 2 per row on mobile, 3 on small screens, 6 on larger screens */}
                                        <Row className="g-2">
                                            <Col xs={6} sm={4} md={2} className="text-center CardColor p-2">
                                                <small>GUESTS</small>
                                                <div>{room.maxGuests || 2}</div>
                                            </Col>
                                            <Col xs={6} sm={4} md={2} className="text-center CardColor p-2">
                                                <small>BEDS</small>
                                                <div>{room.bedOptions.reduce((sum, bed) => sum + bed.count, 0) || 1}</div>
                                            </Col>
                                            <Col xs={6} sm={4} md={2} className="text-center CardColor p-2">
                                                <small>BATHROOM</small>
                                                <div>{room.isBathroomPrivate ? 'PRIVATE' : 'SHARED'}</div>
                                            </Col>
                                            <Col xs={6} sm={4} md={2} className="text-center CardColor p-2">
                                                <small>PRICE</small>
                                                <div>${room.price}</div>
                                            </Col>
                                            <Col xs={6} sm={4} md={2} className="text-center CardColor p-2">
                                                <small>NO. OF THIS TYPE</small>
                                                <div>{room.numberOfRooms || 1}</div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                            ))
                        ) : (
                            <div className="text-center p-4 border rounded">
                                <p>No rooms added yet. Click "Add Another Room" to add rooms to your property.</p>
                            </div>
                        )}
                        <div className="d-flex justify-content-between align-items-center">
                            <Button
                                className="InactiveBtn  d-flex d-md-none"
                                onClick={() => handleRemoveRoom(index)}
                            >
                                Delete
                            </Button>
                            <Button
                                className="InactiveBtn d-flex d-md-none"
                                onClick={() => handleEditRoom(index)}
                            >
                                Edit
                            </Button>
                            <Button
                                onClick={() => setActiveTab('rooms')}
                                className={"InactiveBtn"}
                            >
                                Add Another Room
                            </Button>
                        </div>
                    </Col>
                )}
            </Container>
            <div className={`d-flex ${showBlankPage && activeTab !== 'roomDetails' && activeTab !== 'rooms' ? ' justify-content-between' : ' justify-content-end'}`}>
                {showBlankPage && activeTab !== 'roomDetails' && activeTab !== 'rooms' && (
                    <Button
                        onClick={handleBack}
                        className={"mt-4 activeBtn"}
                    >
                        Back
                    </Button>
                )}
                {activeTab === 'rooms' && (
                    <Button
                        className="me-2 mt-4 InactiveBtn"
                        onClick={handleAddRoom}
                    >
                        Save and Duplicate
                    </Button>
                )}
                <Button
                    onClick={
                        !showBlankPage
                            ? handleContinue
                            : (activeTab === 'roomDetails'
                                ? handleSave
                                : (activeTab === 'rooms'
                                    ? handleSaveAndViewRooms
                                    : () => handleTabChange('rooms')))
                    }
                    className={"mt-4 activeBtn"}
                    disabled={isLoading}
                >
                    {isLoading && activeTab === 'roomDetails' ? (
                        <span>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            Saving...
                        </span>
                    ) : (
                        activeTab === 'roomDetails'
                            ? 'Save'
                            : (activeTab === 'rooms'
                                ? 'Next'
                                : (showBlankPage ? 'Next' : 'Continue'))
                    )}
                </Button>
            </div>
            <style jsx>{`
                .blink {
                    animation: blink-animation 0.5s ease-in-out 3;
                }
                
                @keyframes blink-animation {
                    0% { opacity: 1; }
                    50% { opacity: 0.3; }
                    100% { opacity: 1; }
                }
            `}</style>
        </>
    );
};