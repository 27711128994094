import React, { useState, useEffect } from "react";
import { Container, Form, Row, Col, Card, Button } from 'react-bootstrap';
import Select from 'react-select';
import Axios from 'axios';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Upload, Checkbox } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import '../AddHotel/AddHotel.css';

const AddRoom = () => {
    const { id } = useParams();
    const [formData, setFormData] = useState({
        roomName: '',
        roomType: '',
        totalRooms: '',
        maxGuests: 2,
        maxAdults: 2,
        maxChildren: 1,
        bathroomPrivate: true,
        roomSize: '',
        pricePerNight: '',
        isSmokingAllowed: false,
    });

    const [activeTab, setActiveTab] = useState('rooms');
    const [bedCounts, setBedCounts] = useState({});
    const [roomFiles, setRoomFiles] = useState([]);
    const [roomAmenities, setRoomAmenities] = useState([]);
    const [bedCategories, setBedCategories] = useState([]);
    const [amenities, setAmenities] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [property, setProperty] = useState(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Fetch data on component mount
    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const accessToken = localStorage.getItem('accessToken');

                // Fetch property data
                const propertyResponse = await Axios.get(
                    `https://rentvip-apis.netsolutionindia.com/api/v1/property/${id}`,
                    { headers: { 'authorization': accessToken } }
                );
                console.log("Property data:", propertyResponse.data);
                setProperty(propertyResponse.data.data);

                // Fetch amenities
                const amenitiesResponse = await Axios.get(
                    'https://rentvip-apis.netsolutionindia.com/api/v1/amenity',
                    { headers: { 'authorization': accessToken } }
                );
                console.log("Amenities data:", amenitiesResponse.data);
                setAmenities(amenitiesResponse.data?.data || []);

                // Fetch bed categories
                const bedCategoriesResponse = await Axios.get(
                    'https://rentvip-apis.netsolutionindia.com/api/v1/property/beds/category',
                    { headers: { 'authorization': accessToken } }
                );
                console.log("Bed categories data:", bedCategoriesResponse.data);
                setBedCategories(bedCategoriesResponse.data?.data || []);

            } catch (error) {
                console.error('Error fetching data:', error);
                toast.error('Failed to fetch data. Please try again.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [id]);

    const handleBedCountChange = (bedId, action) => {
        setBedCounts(prev => {
            const currentCount = prev[bedId] || 0;
            let newCount;
            if (action === 'increment') {
                newCount = currentCount + 1;
            } else if (action === 'decrement') {
                newCount = Math.max(0, currentCount - 1); // Prevent negative numbers
            }
            return {
                ...prev,
                [bedId]: newCount
            };
        });
    };

    const handleRoomAmenitiesChange = (e, amenity) => {
        const isChecked = e.target.checked;
        console.log("Room amenity change:", amenity, isChecked);
        if (isChecked) {
            setRoomAmenities(prev => [...prev, {
                _id: amenity._id,
                name: amenity.title,
                isSpecial: false
            }]);
        } else {
            setRoomAmenities(prev =>
                prev.filter(a => a._id !== amenity._id)
            );
        }
    };

    const handleRoomDetailsChange = (field, value) => {
        console.log("Room detail change:", field, value);
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const uploadFile = async (file) => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('type', '3');

            const accessToken = localStorage.getItem('accessToken');
            const response = await Axios.post(
                'https://rentvip-apis.netsolutionindia.com/api/v1/files/upload',
                formData,
                {
                    headers: {
                        'authorization': accessToken,
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );
            return {
                fileId: response.data.data.fileId,
                fileUrl: response.data.data.fileUrl
            };
        } catch (error) {
            console.error('Error uploading file:', error);
            toast.error('Error uploading file');
            return null;
        }
    };

    const handleAddRoom = () => {
        // Validate required fields
        if (!formData.roomName || !formData.roomType) {
            toast.error('Room name and type are required');
            return;
        }

        // Create room object from current form data
        const newRoom = {
            roomName: formData.roomName,
            roomType: String(formData.roomType || ''),
            amenities: roomAmenities.map(amenity => ({
                _id: amenity._id,
                isSpecial: Boolean(amenity.isSpecial)
            })),
            numberOfRooms: parseInt(formData.totalRooms) || 1,
            maxAdults: parseInt(formData.maxAdults) || 2,
            maxChildren: parseInt(formData.maxChildren) || 1,
            maxGuests: parseInt(formData.maxGuests) || 2,
            roomSize: formData.roomSize || "0",
            price: parseFloat(formData.pricePerNight) || 0,
            isSmokingAllowed: formData.isSmokingAllowed || false,
            isBathroomPrivate: formData.bathroomPrivate || false,
            bedOptions: Object.entries(bedCounts).map(([bedType, count]) => ({
                bedType,
                count: parseInt(count) || 0
            })).filter(bed => bed.count > 0),
            roomPhotos: [], // Will be populated with uploaded IDs
            tempRoomPhotos: roomFiles // Temporarily store files for preview
        };

        // Add to rooms array
        setRooms([...rooms, newRoom]);

        // Reset room form fields for next room
        setFormData({
            roomName: '',
            roomType: '',
            totalRooms: '',
            maxGuests: 2,
            maxAdults: 2,
            maxChildren: 1,
            bathroomPrivate: true,
            roomSize: '',
            pricePerNight: '',
            isSmokingAllowed: false,
        });
        setBedCounts({});
        setRoomAmenities([]);
        setRoomFiles([]);

        // Show success message
        toast.success('Room saved! You can add another room or continue.');

        // Optionally switch to room details view
        setActiveTab('roomDetails');
    };

    const handleRemoveRoom = (index) => {
        const updatedRooms = [...rooms];
        updatedRooms.splice(index, 1);
        setRooms(updatedRooms);
        toast.success('Room removed successfully');
    };

    const handleEditRoom = (index) => {
        const roomToEdit = rooms[index];

        // Populate form with room data
        setFormData({
            roomName: roomToEdit.roomName,
            roomType: roomToEdit.roomType,
            totalRooms: roomToEdit.numberOfRooms,
            maxGuests: roomToEdit.maxGuests,
            bathroomPrivate: roomToEdit.isBathroomPrivate,
            roomSize: roomToEdit.roomSize,
            pricePerNight: roomToEdit.price,
            maxAdults: roomToEdit.maxAdults,
            maxChildren: roomToEdit.maxChildren,
            isSmokingAllowed: roomToEdit.isSmokingAllowed
        });

        // Set bed counts
        const newBedCounts = {};
        roomToEdit.bedOptions.forEach(bed => {
            newBedCounts[bed.bedType] = bed.count;
        });
        setBedCounts(newBedCounts);

        // Set room amenities
        setRoomAmenities(roomToEdit.amenities);

        // Set room files
        setRoomFiles(roomToEdit.tempRoomPhotos || []);

        // Remove the room from the array
        handleRemoveRoom(index);

        // Switch to rooms tab for editing
        setActiveTab('rooms');
    };

    const handleRadioChange = (field, value) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleSaveAllRooms = async () => {
        try {
            setIsLoading(true);

            if (rooms.length === 0) {
                toast.error('Please add at least one room before saving');
                setIsLoading(false);
                return;
            }

            // Upload room photos and prepare room data
            const roomsWithPhotos = [];

            for (const room of rooms) {
                const photoIds = [];

                // Upload each photo for this room
                if (room.tempRoomPhotos && room.tempRoomPhotos.length > 0) {
                    for (const file of room.tempRoomPhotos) {
                        const uploadResult = await uploadFile(file);
                        if (uploadResult?.fileId) {
                            photoIds.push(uploadResult.fileId);
                        }
                    }
                }

                // Add room with photo IDs to array
                const roomData = {
                    ...room,
                    roomPhotos: photoIds
                };

                // Remove temp photos as it's not needed for API
                delete roomData.tempRoomPhotos;

                roomsWithPhotos.push(roomData);
            }

            // Prepare and send API request
            const accessToken = localStorage.getItem('accessToken');
            const response = await Axios.patch(
                `https://rentvip-apis.netsolutionindia.com/api/v1/hotel/addRooms/?propertyId=${id}`,
                { rooms: roomsWithPhotos },
                {
                    headers: {
                        'Authorization': accessToken,
                        'Content-Type': 'application/json'
                    }
                }
            );

            console.log("API Response:", response.data);

            if (response.data.statusCode === 200) {
                toast.success('Rooms added successfully!');
                navigate(`/property/${id}`);
            } else {
                toast.error(response.data.message || 'Failed to add rooms');
            }

        } catch (error) {
            console.error('Error saving rooms:', error);
            toast.error(error.response?.data?.message || 'Failed to save rooms');
        } finally {
            setIsLoading(false);
        }
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            borderRadius: '0.375rem',
            border: '1px solid #ced4da',
            minHeight: '40px'
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#6c757d'
        })
    };

    return (
        <>
            <style jsx>{`
                .blink {
                    animation: blink-animation 0.5s ease-in-out 3;
                }
                
                @keyframes blink-animation {
                    0% { opacity: 1; }
                    50% { opacity: 0.3; }
                    100% { opacity: 1; }
                }
                
                .CardColor {
                    background: #FFF8F0;
                    padding: 8px;
                    border-radius: 4px;
                }
                
                .activeBtn {
                    background-color: #f28c28 !important;
                    border-color: #f28c28 !important;
                    color: white !important;
                }
                
                .InactiveBtn {
                    background-color: white !important;
                    border-color: #f28c28 !important;
                    color: #f28c28 !important;
                }
            `}</style>

            {property && (
                <div className="mb-3">
                    <h4>Adding rooms to: {property.name}</h4>
                    <p className="text-muted">{property.location}</p>
                </div>
            )}

            <Container className='p-4 bg-white rounded-3'>
                <div className="mb-4">
                    <div className="d-flex gap-4">
                        <Button
                            onClick={() => setActiveTab('rooms')}
                            className={`${activeTab === 'rooms' ? 'activeBtn' : 'InactiveBtn'}`}
                        >
                            Room Details
                        </Button>
                        <Button
                            onClick={() => setActiveTab('roomDetails')}
                            className={`${activeTab === 'roomDetails' ? 'activeBtn' : 'InactiveBtn'}`}
                        >
                            Room Summary
                            {rooms.length > 0 && (
                                <span
                                    id="roomCountIndicator"
                                    className="ms-2 badge bg-light text-dark"
                                    style={{ borderRadius: '50%' }}
                                >
                                    {rooms.length}
                                </span>
                            )}
                        </Button>
                    </div>
                </div>

                {activeTab === 'rooms' && (
                    <Row>
                        {rooms.length > 0 && (
                            <Col md={12} className="mb-3">
                                <div className="alert alert-success" role="alert">
                                    <strong>{rooms.length} room{rooms.length > 1 ? 's' : ''} added</strong>.
                                    You can <a href="#" onClick={(e) => { e.preventDefault(); setActiveTab('roomDetails'); }}>
                                        view all rooms
                                    </a> or continue adding more.
                                </div>
                            </Col>
                        )}

                        <Col md={6}>
                            <Form>
                                <Form.Group className="mb-4">
                                    <Form.Label>Name of the room</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Double Room with Runway View"
                                        value={formData.roomName}
                                        onChange={(e) => handleRoomDetailsChange('roomName', e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label>What type of room is this?</Form.Label>
                                    <Form.Select
                                        value={formData.roomType || ""}
                                        onChange={(e) => {
                                            if (e && e.target) {
                                                const newValue = e.target.value;
                                                console.log("Room type selected:", newValue);
                                                handleRoomDetailsChange('roomType', newValue);
                                            }
                                        }}
                                        required
                                    >
                                        <option value="">Select Room Type</option>
                                        <option value="SINGLE">Single Room</option>
                                        <option value="DOUBLE">Double Room</option>
                                        <option value="SUITE">Suite</option>
                                        <option value="KING">Deluxe King Room</option>
                                        <option value="EXECUTIVE">Executive Room</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label>No. of Rooms (Of this type)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={formData.totalRooms || ''}
                                        onChange={(e) => handleRoomDetailsChange('totalRooms', e.target.value)}
                                        min="1"
                                        placeholder="Enter number of rooms"
                                    />
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label>What bed options are available in this room?</Form.Label>
                                    <div>
                                        {bedCategories.map((bed) => (
                                            <div key={bed._id} className="d-flex justify-content-between align-items-center mb-2">
                                                <span>
                                                    <strong className="fs-4">
                                                        <img
                                                            src={bed.icon}
                                                            alt={bed.name}
                                                            style={{ width: '24px', height: '24px' }}
                                                            className="me-2"
                                                        />
                                                    </strong>
                                                    <small>{bed.name}</small>
                                                </span>
                                                <div className="d-flex align-items-center border rounded">
                                                    <Button
                                                        variant="outline-secondary"
                                                        className='border-0 fs-4'
                                                        size="sm"
                                                        onClick={() => handleBedCountChange(bed._id, 'decrement')}
                                                        disabled={!bedCounts[bed._id]} // Disable if count is 0
                                                        onMouseEnter={(e) => {
                                                            if (bedCounts[bed._id]) { // Only change style if button is enabled
                                                                e.target.style.color = '#f28c28';
                                                                e.target.style.backgroundColor = '#fff';
                                                            }
                                                        }}
                                                        onMouseLeave={(e) => {
                                                            if (bedCounts[bed._id]) {
                                                                e.target.style.color = '';
                                                                e.target.style.backgroundColor = '';
                                                            }
                                                        }}
                                                    >
                                                        -
                                                    </Button>
                                                    <span className="mx-2 text-center" style={{ width: '25px' }}>
                                                        {bedCounts[bed._id] || 0}
                                                    </span>
                                                    <Button
                                                        variant="outline-secondary"
                                                        className='border-0 fs-4'
                                                        size="sm"
                                                        onClick={() => handleBedCountChange(bed._id, 'increment')}
                                                        onMouseEnter={(e) => {
                                                            e.target.style.color = '#f28c28';
                                                            e.target.style.backgroundColor = '#fff';
                                                        }}
                                                        onMouseLeave={(e) => {
                                                            e.target.style.color = '';
                                                            e.target.style.backgroundColor = '';
                                                        }}
                                                    >
                                                        +
                                                    </Button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </Form.Group>
                            </Form>
                        </Col>

                        <Col md={6}>
                            <Form>
                                <Form.Group className="mb-4">
                                    <Form.Label>Maximum Adults</Form.Label>
                                    <Select
                                        styles={customStyles}
                                        options={[
                                            { value: 1, label: '1' },
                                            { value: 2, label: '2' },
                                            { value: 3, label: '3' },
                                            { value: 4, label: '4' },
                                        ]}
                                        value={{ value: formData.maxAdults, label: String(formData.maxAdults) }}
                                        onChange={(option) => handleRoomDetailsChange('maxAdults', option.value)}
                                        placeholder="Select maximum adults"
                                    />
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label>Maximum Children</Form.Label>
                                    <Select
                                        styles={customStyles}
                                        options={[
                                            { value: 0, label: '0' },
                                            { value: 1, label: '1' },
                                            { value: 2, label: '2' },
                                            { value: 3, label: '3' },
                                        ]}
                                        value={{ value: formData.maxChildren, label: String(formData.maxChildren) }}
                                        onChange={(option) => handleRoomDetailsChange('maxChildren', option.value)}
                                        placeholder="Select maximum children"
                                    />
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label>Maximum Guests</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={formData.maxGuests}
                                        onChange={(e) => handleRoomDetailsChange('maxGuests', e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label>What is the room size?</Form.Label>
                                    <div className="d-flex">
                                        <Form.Control
                                            type="text"
                                            value={formData.roomSize || ""}
                                            onChange={(e) => {
                                                if (e && e.target) {
                                                    const value = e.target.value;
                                                    console.log("Room size changed:", value);
                                                    handleRoomDetailsChange('roomSize', value);
                                                }
                                            }}
                                            className="me-2"
                                            style={{ flex: 1 }}
                                        />
                                        <Select
                                            styles={{
                                                ...customStyles,
                                                container: (provided) => ({
                                                    ...provided,
                                                    flex: 1
                                                })
                                            }}
                                            options={[
                                                { value: 'square meters', label: 'square meters' },
                                                { value: 'square feet', label: 'square feet' },
                                            ]}
                                            defaultValue={{ value: 'square meters', label: 'square meters' }}
                                        />
                                    </div>
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label>Set the price</Form.Label>
                                    <div className="d-flex">
                                        <Form.Control
                                            type="text"
                                            value={formData.pricePerNight}
                                            onChange={(e) => handleRoomDetailsChange('pricePerNight', e.target.value)}
                                            className="me-2"
                                            style={{ flex: 1 }}
                                        />
                                        <Select
                                            styles={{
                                                ...customStyles,
                                                container: (provided) => ({
                                                    ...provided,
                                                    flex: 1
                                                })
                                            }}
                                            options={[
                                                { value: 'per night', label: 'per night' },
                                            ]}
                                            defaultValue={{ value: 'per night', label: 'per night' }}
                                        />
                                    </div>
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label>Is smoking allowed in this room?</Form.Label>
                                    <div>
                                        <Form.Check
                                            type="radio"
                                            label="Yes"
                                            name="smoking"
                                            id="smokingYes"
                                            checked={formData.isSmokingAllowed === true}
                                            onChange={() => handleRadioChange('isSmokingAllowed', true)}
                                            inline
                                            className="custom-radio"
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="No"
                                            name="smoking"
                                            id="smokingNo"
                                            checked={formData.isSmokingAllowed === false}
                                            onChange={() => handleRadioChange('isSmokingAllowed', false)}
                                            inline
                                            className="custom-radio"
                                        />
                                    </div>
                                </Form.Group>

                                <Form.Group className="mb-4">
                                    <Form.Label>Is the bathroom private?</Form.Label>
                                    <div>
                                        <Form.Check
                                            type="radio"
                                            label="Yes"
                                            name="bathroom"
                                            id="bathroomYes"
                                            checked={formData.bathroomPrivate === true}
                                            onChange={() => handleRadioChange('bathroomPrivate', true)}
                                            inline
                                            className="custom-radio"
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="No"
                                            name="bathroom"
                                            id="bathroomNo"
                                            checked={formData.bathroomPrivate === false}
                                            onChange={() => handleRadioChange('bathroomPrivate', false)}
                                            inline
                                            className="custom-radio"
                                        />
                                    </div>
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col md={12}>
                            <Form.Group className="mb-4">
                                <Form.Label>What amenities are available in this room?</Form.Label>
                                <input
                                    type="text"
                                    className="form-control mb-3"
                                    value={roomAmenities.map(a => a.name).join(', ')}
                                    readOnly
                                    placeholder="Select Room Amenities"
                                />
                                <div className="border rounded p-3">
                                    <div className="d-flex flex-wrap">
                                        {amenities.map((amenity) => (
                                            <div key={amenity?._id} className="form-check col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={amenity?._id}
                                                    id={`room-amenity-${amenity?._id}`}
                                                    onChange={(e) => handleRoomAmenitiesChange(e, amenity)}
                                                    checked={roomAmenities.some(a => a._id === amenity?._id)}
                                                />
                                                <small htmlFor={`room-amenity-${amenity?._id}`}>
                                                    {amenity?.title}
                                                </small>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-4">
                                <Form.Label>Room Photos</Form.Label>
                                <Upload
                                    listType="picture-card"
                                    fileList={roomFiles.map((file, index) => ({
                                        uid: index,
                                        name: file.name || `image-${index}`,
                                        status: 'done',
                                        url: file.preview,
                                        originFileObj: file
                                    }))}
                                    onPreview={(file) => {
                                        const url = file.url || file.preview;
                                        window.open(url, '_blank');
                                    }}
                                    onRemove={(file) => {
                                        const index = file.uid;
                                        const newFiles = [...roomFiles];
                                        newFiles.splice(index, 1);
                                        setRoomFiles(newFiles);
                                    }}
                                    beforeUpload={(file) => {
                                        if (roomFiles.length >= 20) {
                                            toast.error('You can only upload up to 20 images.');
                                            return false;
                                        }
                                        const newFile = Object.assign(file, {
                                            preview: URL.createObjectURL(file)
                                        });
                                        setRoomFiles(prev => [...prev, newFile]);
                                        return false; // Prevent default upload behavior
                                    }}
                                    accept="image/*"
                                    multiple
                                >
                                    {roomFiles.length < 20 && (
                                        <div>
                                            <PlusOutlined />
                                            <div style={{ marginTop: 8 }}>Upload</div>
                                        </div>
                                    )}
                                </Upload>
                            </Form.Group>
                        </Col>

                        <Col md={12} className="mt-3">
                            <div className="d-flex justify-content-end">
                                <Button
                                    onClick={() => navigate(`/property/${id}`)}
                                    className="me-2 InactiveBtn"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={handleAddRoom}
                                    className="activeBtn"
                                >
                                    Save Room
                                </Button>
                            </div>
                        </Col>
                    </Row>
                )}

                {activeTab === 'roomDetails' && (
                    <Col md={12}>
                        <div className="mb-3">
                            <h4 className="mb-3">Room Summary</h4>
                            <p className="text-muted">
                                {rooms.length > 0
                                    ? `You have added ${rooms.length} room${rooms.length > 1 ? 's' : ''} to this property.`
                                    : 'No rooms added yet. Add rooms to complete your property listing.'}
                            </p>
                        </div>

                        {rooms.length > 0 ? (
                            rooms.map((room, index) => (
                                <Row key={index} className="border rounded p-3 mb-3" style={{ borderColor: '#f28c28' }}>
                                    {/* Room Image - Full width on mobile, 2 columns on larger screens */}
                                    <Col xs={12} md={2} className="text-center mb-3 mb-md-0">
                                        {room.tempRoomPhotos && room.tempRoomPhotos.length > 0 ? (
                                            <img
                                                src={room.tempRoomPhotos[0].preview}
                                                alt={room.roomName}
                                                className="img-fluid rounded"
                                                style={{ maxHeight: '150px', objectFit: 'cover' }}
                                            />
                                        ) : (
                                            <div className="bg-light rounded p-3">No Image</div>
                                        )}
                                    </Col>

                                    {/* Room Details - Full width on mobile, 10 columns on larger screens */}
                                    <Col xs={12} md={10}>
                                        <div className="d-flex justify-content-between align-items-center mb-3 flex-wrap">
                                            <h5 className="text-truncate" style={{ color: '#f28c28', marginBottom: 0 }}>
                                                {room.roomName || 'Unnamed Room'}
                                            </h5>

                                            {/* Buttons - Stack vertically on small screens */}
                                            <div className="mt-2 mt-md-0 flex-wrap d-none d-md-flex">
                                                <Button
                                                    className="me-2 mb-2 mb-md-0"
                                                    style={{ backgroundColor: '#fff', color: '#f28c28', border: '1px solid #f28c28' }}
                                                    size="sm"
                                                    onClick={() => handleRemoveRoom(index)}
                                                >
                                                    Delete
                                                </Button>
                                                <Button
                                                    style={{ backgroundColor: '#f28c28', color: '#fff', border: '1px solid #f28c28' }}
                                                    size="sm"
                                                    onClick={() => handleEditRoom(index)}
                                                >
                                                    Edit
                                                </Button>
                                            </div>
                                        </div>

                                        {/* Stats Cards - 2 per row on mobile, 3 on small screens, 6 on larger screens */}
                                        <Row className="g-2">
                                            <Col xs={6} sm={4} md={2} className="text-center CardColor p-2">
                                                <small>GUESTS</small>
                                                <div>{room.maxGuests || 2}</div>
                                            </Col>
                                            <Col xs={6} sm={4} md={2} className="text-center CardColor p-2">
                                                <small>BEDS</small>
                                                <div>{room.bedOptions.reduce((sum, bed) => sum + bed.count, 0) || 1}</div>
                                            </Col>
                                            <Col xs={6} sm={4} md={2} className="text-center CardColor p-2">
                                                <small>BATHROOM</small>
                                                <div>{room.isBathroomPrivate ? 'PRIVATE' : 'SHARED'}</div>
                                            </Col>
                                            <Col xs={6} sm={4} md={2} className="text-center CardColor p-2">
                                                <small>PRICE</small>
                                                <div>${room.price}</div>
                                            </Col>
                                            <Col xs={6} sm={4} md={2} className="text-center CardColor p-2">
                                                <small>NO. OF THIS TYPE</small>
                                                <div>{room.numberOfRooms || 1}</div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                            ))
                        ) : (
                            <div className="text-center p-4 border rounded">
                                <p>No rooms added yet. Click "Add Another Room" to add rooms to your property.</p>
                            </div>
                        )}
                        <div className="d-flex justify-content-between align-items-center">
                            <Button
                                className="InactiveBtn  d-flex d-md-none"
                                onClick={() => handleRemoveRoom(index)}
                            >
                                Delete
                            </Button>
                            <Button
                                className="InactiveBtn d-flex d-md-none"
                                onClick={() => handleEditRoom(index)}
                            >
                                Edit
                            </Button>
                            <Button
                                onClick={() => setActiveTab('rooms')}
                                className={"InactiveBtn"}
                            >
                                Add Another Room
                            </Button>

                        </div>
                    </Col>
                )}
            </Container>
            {activeTab === 'roomDetails' && (
                <div className={`d-flex ${activeTab !== 'roomDetails' ? ' justify-content-end' : ' justify-content-between'}`}>
                    <Button
                        onClick={() => navigate(`/property/${id}`)}
                        className={"mt-4 activeBtn"}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSaveAllRooms}
                        className={"mt-4 activeBtn"}
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <span>
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                Saving...
                            </span>
                        ) : (
                            'Save'
                        )}
                    </Button>
                </div>)}
        </>
    );
};

export default AddRoom; 