import React, { Fragment, useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import Slider from "react-slick";
import { connect, useDispatch, useSelector } from "react-redux";
import MarkdownIt from "markdown-it";
import axios from "axios";
import { Modal, message } from 'antd';

import { CommonAction } from "../../../../store/common/thunk";
import EditProperty from "./EditPopup";
import COMMON_ACTIONS from "../../../../store/common/actions";
import { EDIT_PROPERTY_TYPE, MESSAGES, USER_TYPE } from "../../../../constants";
import {
    BedroomWithBathroom,
    BedroomWithoutBathroom,
    LocationIcon,
} from "../../../../assets/images";
import useWindowWidth from "../../../../hooks/useWindowWidth";
import styles from "./style.module.css";
import viewStyles from "../../styles/view.module.css";
import pen from "../../../../assets/images/pen.png";
import { Button } from "react-bootstrap";
import ImageModal from "./EditPopup/ImageModal";
import { CiBoxList, CiGrid41 } from "react-icons/ci";
import { IoFilterOutline } from "react-icons/io5";
import toast from "react-hot-toast";
import EditRoom from "../EditRoom";

const ViewProperty = ({
    loader,
    specificProperty,
    getRecommendedProperties,
}) => {
    const settings = {
        dots: false,
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    const bigSliderRef = useRef(null);
    const smallSliderRef = useRef(null);



    var mbslides = {
        dots: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    infinite: true,
                    speed: 1000,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: true,
                    autoplay: true,
                    autoplaySpeed: 1400,
                },
            },
        ],
    };

    const { id } = useParams();
    const dispatch = useDispatch();
    const windowWidth = useWindowWidth();
    const mdParser = new MarkdownIt({
        html: true,
        linkify: true,
        typographer: true,
    });
    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
    const [editProperty, setEditProperty] = useState({ show: false, type: "" });
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [viewMode, setViewMode] = useState("grid");
    const [showGalleryModal, setShowGalleryModal] = useState(false);
    const [modalImageIndex, setModalImageIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [displayCount, setDisplayCount] = useState(6);

    const modalBigSliderRef = useRef(null);
    const modalSmallSliderRef = useRef(null);
    const maxLength = 450;
    const openGalleryModal = (index) => {
        setModalImageIndex(index);
        setShowGalleryModal(true);
    };

    const closeGalleryModal = () => {
        setShowGalleryModal(false);
    };

    const [locationDetails, setLocationDetails] = useState({
        area: "",
        building: "",
        city: "",
    });
    const [propertyFeatures, setPropertyFeatures] = useState({
        bedrooms: [],
        nonAttachedBathrooms: [],
        bedIcons: [],
    });
    const [propertyItems, setPropertyItems] = useState({
        bedrooms: 0,
        bathrooms: 0,
        capacity: 0,
        beds: 0,
    });
    const [recommendedProperties, setRecommendedProperties] = useState({
        properties: [],
        count: 0,
    });

    const { property, propertyLoader, user } = useSelector(
        (state) => state.common
    );
    console.log(property?.hotelRooms?.length, "propertypropertyyyy");

    const imageCategory = property?.imageCategories?.[selectedCategoryIndex]?.categoriesImages || [];
    const bigSliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        asNavFor: smallSliderRef.current,
        ref: bigSliderRef,
        afterChange: (index) => {
            setActiveIndex(index); // Update active index
            smallSliderRef.current?.slickGoTo(index); // Sync small slider
        },
    };

    const smallSliderSettings = {
        dots: false,
        infinite: imageCategory.length > 1,
        speed: 500,
        slidesToShow: imageCategory.length >= 3 ? 3 : imageCategory.length,
        slidesToScroll: 1,
        swipeToSlide: true,
        arrows: imageCategory.length > 1,
        focusOnSelect: true,
        asNavFor: bigSliderRef.current,
        ref: smallSliderRef,
        centerMode: false,
        swipeToSlide: true,           // Allow swipe to slide
        draggable: imageCategory.length > 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: false
                },
            },
        ],
    };
    const modalBigSliderSettings = {
        ...bigSliderSettings,
        asNavFor: modalSmallSliderRef.current,
        ref: modalBigSliderRef,
        initialSlide: modalImageIndex,
        afterChange: (index) => {
            setModalImageIndex(index);
            modalSmallSliderRef.current?.slickGoTo(index);
        }
    };
    const modalSmallSliderSettings = {
        ...smallSliderSettings,
        asNavFor: modalBigSliderRef.current,
        ref: modalSmallSliderRef,
        initialSlide: modalImageIndex,
        slidesToShow: 11,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 7,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 374,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },


        ]
    };

    useEffect(() => {
        if (smallSliderRef.current && imageCategory.length > 0) {
            smallSliderRef.current.slickGoTo(0);
        }
    }, [imageCategory])
    const handlePropertyOptionClick = (type) => {
        if (user.userType !== USER_TYPE.VENDOR) return;

        setEditProperty({ show: true, type });
    };

    const [imageCategories, setImageCategories] = useState([]);
    const isMounted = useRef(true);
    const cancelTokenSourceRef = useRef(null);

    useEffect(() => {
        isMounted.current = true;
        cancelTokenSourceRef.current = axios.CancelToken.source();
        return () => {
            isMounted.current = false;
            if (cancelTokenSourceRef.current) {
                cancelTokenSourceRef.current.cancel('Component unmounted');
            }
            // Reset displayCount on unmount to prevent state updates
            setDisplayCount(6);
        };
    }, []);

    useEffect(() => {
        const query = { id };
        const options = {
            cancelToken: cancelTokenSourceRef.current?.token
        };

        specificProperty(query, loader, options);

        const recommendedPropertiesQuery = {
            propertyId: id,
            offset: 0,
            limit: 6,
        };

        getRecommendedProperties(recommendedPropertiesQuery, loader, options)
            .then((res) => {
                if (isMounted.current) {
                    setRecommendedProperties({
                        properties: res?.properties || [],
                        count: res?.count || 0,
                    });
                }
            })
            .catch(error => {
                if (!axios.isCancel(error)) {
                    console.error("Error fetching recommended properties:", error);
                }
            });

        const fetchCategoriesSafely = async () => {
            try {
                const res = await getImageCategories(options);
                if (isMounted.current) {
                    setImageCategories(res?.data || []);
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error("Error fetching categories:", error);
                }
            }
        };

        fetchCategoriesSafely();
    }, []);

    const activeDeactiveRoom = async (roomId, currentStatus) => {
        try {
            setIsLoading(true);
            const accessToken = localStorage.getItem('accessToken');
            const response = await axios.patch(
                `https://rentvip-apis.netsolutionindia.com/api/v1/hotel/activeDeactiveRoom/${id}/${roomId}`,
                { status: !currentStatus }, // Toggle the current status
                { headers: { 'authorization': accessToken } }
            );

            if (response?.status && isMounted.current) {
                toast.success(`Room ${!currentStatus ? 'activated' : 'deactivated'} successfully!`);
                const query = { id };
                const options = {
                    cancelToken: cancelTokenSourceRef.current?.token
                };
                await specificProperty(query, loader, options);
            }
        } catch (error) {
            if (!axios.isCancel(error) && isMounted.current) {
                console.error('Error updating room status:', error);
                message.error('Failed to update room status. Please try again.');
            }
        } finally {
            if (isMounted.current) {
                setIsLoading(false);
            }
        }
    };


    useEffect(() => {
        if (Object.keys(property).length) {
            const selectedCity = property.cityData.find((item) => item.isSelected);
            const selectedArea = selectedCity.areaData.find(
                (item) => item.isSelected
            );
            const selectedBuilding = selectedArea.buildingData.find(
                (item) => item.isSelected
            );

            let bedrooms = [];
            let bathroomCount = 0;
            let bedCount = 0;

            for (const item of property.bedRoomData) {
                let obj = item;
                if (item.bathRoom) {
                    obj.bathRoomData = property.bathRooms.find(
                        (it) => it.bedRoomNumber === item.bedRoomNumber
                    );
                    bathroomCount += 1;
                } else obj.bathRoomData = null;

                let icons = item.bedData.map((item) => ({ icon: item.bedIcon }));

                bedCount += item.bedData.length;
                obj.bedIcons = icons;

                bedrooms.push(obj);
            }

            const nonAttachedBathrooms = property.bathRooms.filter(
                (item) => !item.bedRoomNumber
            );

            dispatch({
                type: COMMON_ACTIONS.SET_IMAGES,
                key: "selectedImage",
                value: property.images,
            });
            dispatch({
                type: COMMON_ACTIONS.SET_IMAGES,
                key: "images",
                value: property.images,
            });

            setPropertyItems({
                ...propertyItems,
                capacity: property.personCapacity,
                bedrooms: bedrooms.length,
                bathrooms: bathroomCount + nonAttachedBathrooms.length,
                beds: bedCount,
            });

            setPropertyFeatures({ bedrooms: bedrooms, nonAttachedBathrooms });
            setLocationDetails({
                city: selectedCity.name,
                building: selectedBuilding.name,
                area: selectedArea.name,
            });
        }
    }, [property]);

    const closeEditPropertyModal = () =>
        setEditProperty({ show: false, type: "" });

    const loadMore = () => {
        setDisplayCount(prevCount => prevCount + 6);
    };

    const visibleRooms = property.hotelRooms?.slice(0, displayCount) || [];
    const hasMoreRooms = property.hotelRooms?.length > displayCount;

    if (propertyLoader) {
        return (
            <div className="d-flex justify-content-center align-items-center flex-column loading-text">
                <h5>{MESSAGES.LOADING}</h5>
            </div>
        );
    } else if (!Object.keys(property).length) {
        return (
            <div
                className={`d-flex justify-content-center align-items-center flex-column ${viewStyles["no-data"]}`}
            >
                <i className="fa fa-exclamation-triangle" />
                <h4>{MESSAGES.PROPERTY.NOT_FOUND}</h4>
            </div>
        );
    }
    var catslider = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 100,
        loop: true,
        slidesToShow: 5,
        slidesToScroll: 5
    };

    const handleCategoryClick = (index) => {
        setSelectedCategoryIndex(index);
        setActiveIndex(0); // Reset active index to 0
        bigSliderRef.current?.slickGoTo(0);
        smallSliderRef.current?.slickGoTo(0);
    };
    const handleThumbnailHover = (index) => {
        setActiveIndex(index);
        bigSliderRef.current?.slickGoTo(index); // Hovering updates the big slider
    };

    const toggleDescription = () => {
        setShowFull((prev) => !prev);
    };

    const handleremoveRoom = async (roomId) => {
        Modal.confirm({
            title: 'Delete Room',
            content: 'Are you sure you want to delete this room?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                try {
                    const accessToken = localStorage.getItem('accessToken');
                    const { data } = await axios.delete(`https://rentvip-apis.netsolutionindia.com/api/v1/hotel/removeRoom/${id}/${roomId}`, {
                        headers: { 'authorization': accessToken }
                    });

                    if (isMounted.current) {
                        message.success('Room deleted successfully!');
                        const query = { id };
                        const options = {
                            cancelToken: cancelTokenSourceRef.current?.token
                        };
                        specificProperty(query, loader, options);
                    }
                } catch (error) {
                    if (!axios.isCancel(error) && isMounted.current) {
                        console.error('Error deleting room:', error);
                        message.error('Failed to delete room. Please try again.');
                    }
                }
            },
        });
    };

    return (
        <div className="dasboad-property-detail" style={{ fontFamily: "Cambay-Regular, sans-serif" }}>
            <style dangerouslySetInnerHTML={{
                __html: `
          .description {
            border-bottom: none !important;
          }
          .mobie-detail {
            border-bottom: none !important;
          }
          .mobie-detail > div {
            border-bottom: none !important;
          }
          .description > * {
            border-bottom: none !important;
          }
            .gallery-modal .ant-modal-content {
                background-color: black !important;
                height: 100vh;
                border-radius: 0;              
            }
            .gallery-modal .ant-modal {
                max-width: 100% !important;
                margin: 0 !important;
                padding: 0 !important;
                top: 0 !important;
                height: 100vh;
                overflow: hidden;
                
            }
            .gallery-modal .ant-modal-header {
                background-color: black;
                border-bottom: none;
            }
            .gallery-modal .ant-modal-body {
                background-color: black;
            }
            .gallery-modal .ant-modal-title {
                color: white !important;
            }
            .gallery-modal .ant-modal-close {
                color: white !important;
            }
            .gallery-modal .ant-modal-close-x {
                color: white !important;
            }
            .gallery-modal .slick-next:before,
            .gallery-modal .slick-prev:before {
                color: white !important;
                background-color: #FF8C00;
                font-size: 36px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 28px;
                height: 26px;
                position: absolute;
            }
            /* Responsive image size adjustments */
            .gallery-modal .main-image img {
                max-height: 75vh;
                object-fit: contain;
            }
            @media (max-width: 992px) {
                .gallery-modal .main-image img {
                    max-height: 60vh;
                }
                .gallery-modal .thumbnail-container img {
                    height: 110px !important;
                    width: 110px !important;
                }
            }
            @media (max-width: 768px) {
                .gallery-modal .main-image img {
                    max-height: 68vh;
                }
                .gallery-modal .thumbnail-container img {
                    height: 110px !important;
                    width: 110px !important;
                }
            }
            @media (max-width: 576px) {
                .gallery-modal .main-image img {
                    max-height: 70vh;
                }
                .gallery-modal .thumbnail-container img {
                    height: 100px !important;
                    width: 100px !important;
                }
            }
            @media (max-width: 375px) {
                .gallery-modal .main-image img {
                    max-height: 50vh;
                }
                .gallery-modal .thumbnail-container img {
                    height: 60px !important;
                    width: 60px !important;
                }
            }
            @media (max-width: 320px) {
                .gallery-modal .main-image img {
                    max-height: 40vh;
                }
                .gallery-modal .thumbnail-container img {
                    height: 100px !important;
                    width: 100px !important;
                }
            }    
            /* Mobile property image slider styles */
            .mobile-property-slider {
                padding: 0;
                margin-top: 0;
            }
            
            .mobile-property-slider .slick-slider {
                margin: 0;
            }
            
            .mobile-property-slider .slick-slide {
                padding: 0;
            }
            
            .mobile-property-slider .slick-dots {
                bottom: 15px;
                z-index: 5;
            }
            
            .mobile-property-slider .slick-dots li button:before {
                color: white;
                opacity: 0.8;
                font-size: 10px;
            }
            
            .mobile-property-slider .slick-dots li.slick-active button:before {
                color: white;
                opacity: 1;
            }
            
            @media (max-width: 767px) {
                .mobile-property-slider {
                    margin-left: -15px;
                    margin-right: -15px;
                    width: calc(100% + 30px);
                }
                
                .mobile-slide-item img {
                    height: 50vh !important;
                    width: 100%;
                    border-radius: 0;
                }
                
                .container-fluid {
                    padding: 0;
                }
            }
        `
            }} />
            <div className={viewStyles["product-slider-main"]}>
                <div className="container-fluid">
                    {windowWidth <= 767 ? (
                        // Mobile carousel view with full-width images
                        <div className="mobile-property-slider">
                            <Slider
                                dots={true}
                                infinite={true}
                                speed={500}
                                slidesToShow={1}
                                slidesToScroll={1}
                                arrows={false}
                                autoplay={true}
                                autoplaySpeed={3000}
                                className="mobile-main-slider"
                            >
                                {imageCategory.length > 0 ?
                                    imageCategory.map((img, index) => (
                                        <div key={index} className="mobile-slide-item">
                                            <img
                                                src={img}
                                                alt={`Property Image ${index + 1}`}
                                                className="img-fluid w-100"
                                                style={{ height: "40vh", objectFit: "cover" }}
                                                onClick={() => openGalleryModal(index)}
                                            />
                                        </div>
                                    )) :
                                    property?.images?.map((img, index) => (
                                        <div key={index} className="mobile-slide-item">
                                            <img
                                                src={img}
                                                alt={`Property Image ${index + 1}`}
                                                className="img-fluid w-100"
                                                style={{ height: "40vh", objectFit: "cover" }}
                                                onClick={() => openGalleryModal(index)}
                                            />
                                        </div>
                                    ))
                                }
                            </Slider>
                        </div>
                    ) : (
                        // Desktop view with grid layout
                        <div className="row g-1">
                            <div className="col-md-6 mt-4">
                                <img
                                    src={imageCategory.length > 0 ? imageCategory[0] : property?.images?.[0] || "placeholder.jpg"}
                                    className="img-fluid w-100"
                                    alt="Hotel Images"
                                    style={{ height: "70vh", objectFit: "cover", borderBottomLeftRadius: "7px", borderTopLeftRadius: "7px" }}
                                    onClick={() => openGalleryModal(0)}
                                />
                            </div>
                            <div className="col-md-3 mt-4 d-flex flex-column gap-1">
                                <img
                                    src={imageCategory.length > 1 ? imageCategory[1] : (imageCategory[0] || property?.images?.[1] || "placeholder.jpg")}
                                    className="img-fluid w-100"
                                    alt="Hotel Images"
                                    style={{ height: "calc(35vh - 2px)", objectFit: "cover" }}
                                    onClick={() => openGalleryModal(0)}
                                />
                                <img
                                    src={imageCategory.length > 2 ? imageCategory[2] : (imageCategory[0] || property?.images?.[2] || "placeholder.jpg")}
                                    className="img-fluid w-100"
                                    alt="Hotel Images"
                                    style={{ height: "calc(35vh - 2px)", objectFit: "cover" }}
                                    onClick={() => openGalleryModal(0)}
                                />
                            </div>

                            <div className="col-md-3 mt-4 d-flex flex-column gap-1">
                                <img
                                    src={imageCategory.length > 3 ? imageCategory[3] : (imageCategory[0] || property?.images?.[3] || "placeholder.jpg")}
                                    className="img-fluid w-100"
                                    alt="Hotel Images"
                                    style={{ height: "calc(23.33vh - 4px )", objectFit: "cover", borderTopRightRadius: "7px" }}
                                    onClick={() => openGalleryModal(0)}
                                />
                                <img
                                    src={imageCategory.length > 4 ? imageCategory[4] : (imageCategory[0] || property?.images?.[4] || "placeholder.jpg")}
                                    className="img-fluid w-100"
                                    alt="Hotel Images"
                                    style={{ height: "calc(23.33vh - 4px )", objectFit: "cover" }}
                                    onClick={() => openGalleryModal(0)}
                                />
                                <div className="position-relative">
                                    <img
                                        src={imageCategory.length > 5 ? imageCategory[5] : (imageCategory[0] || property?.images?.[5] || "placeholder.jpg")}
                                        className="img-fluid w-100"
                                        alt="Hotel Images"
                                        style={{ height: "calc(23.33vh)", objectFit: "cover", borderBottomRightRadius: "7px" }}
                                        onClick={() => openGalleryModal(0)}
                                    />
                                    <div className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center">
                                        {imageCategory.length > 0 ? (user.userType === USER_TYPE.VENDOR ? (
                                            <Button className='InactiveBtn'
                                                onClick={() =>
                                                    setEditProperty({
                                                        show: true,
                                                        type: EDIT_PROPERTY_TYPE.IMAGES,
                                                    })
                                                }
                                            >
                                                Edit
                                            </Button>
                                        ) : null
                                        ) : (<ImageModal property_id={id} image={imageCategory.length > 0 ? imageCategory : property?.images} imagesId={property?.imagesId} />)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <Modal
                    visible={showGalleryModal}
                    onCancel={closeGalleryModal}
                    footer={null}
                    width="100%"
                    style={{
                        top: 0,
                        padding: 0,
                        maxWidth: '100vw',
                        margin: 0,
                        height: '100vh',
                        backgroundColor: "black",
                        overflow: "hidden",
                    }}
                    title={
                        <div className="image-counter" style={{ color: 'white' }}>
                            {modalImageIndex + 1} / {imageCategory.length > 0 ? imageCategory.length : property?.images?.length || 0}
                        </div>
                    }
                    centered
                    className="gallery-modal"
                >
                    <div className="main-image" style={{ flex: 1 }}>
                        <Slider {...modalBigSliderSettings}>
                            {imageCategory.length > 0 ? (
                                imageCategory.map((img, index) => (
                                    <div key={index} className=" w-100">
                                        <img
                                            className="img-fluid mx-auto"
                                            style={{ height: "74vh" }}
                                            src={img}
                                            alt={`Gallery Image ${index + 1}`}
                                        />
                                    </div>
                                ))
                            ) : (
                                property?.images?.length > 0 ? (
                                    property?.images?.map((img, index) => (
                                        <div key={index} className=" w-100">
                                            <img
                                                className="img-fluid mx-auto"
                                                style={{ height: "74vh" }}
                                                src={img}
                                                alt={`Gallery Image ${index + 1}`}
                                            />
                                        </div>
                                    ))
                                ) : (
                                    <div>
                                        <p style={{ color: 'white', textAlign: 'center' }}>No images found</p>
                                    </div>
                                )
                            )}
                        </Slider>
                    </div>
                    <div className="thumbnail-container" style={{
                        padding: "0px 30px",

                        overflow: "hidden",
                    }}>
                        <Slider {...modalSmallSliderSettings}>
                            {imageCategory.length > 0 ? (
                                imageCategory.map((img, index) => (
                                    <div key={index} style={{ padding: "0 5px" }}>
                                        <div
                                            className="thumbnail"
                                            style={{
                                                borderRadius: "8px",
                                                overflow: "hidden",
                                                // border: index === modalImageIndex ? '2px solid #FF8C00' : '1px solid #444'
                                            }}
                                        >
                                            <img
                                                className="img-fluid"
                                                style={{ height: "123px", width: "123px", border: index === modalImageIndex ? '2px solid #FF8C00' : '1px solid #444' }}
                                                src={img}
                                                alt={`Thumbnail ${index + 1}`}
                                                onClick={() => modalBigSliderRef.current?.slickGoTo(index)}
                                            />
                                        </div>
                                    </div>
                                ))
                            ) : (
                                property?.images?.length > 0 ? (
                                    property?.images?.map((img, index) => (
                                        <div key={index} style={{ padding: "0 5px" }}>
                                            <div
                                                className="thumbnail"
                                                style={{
                                                    borderRadius: "4px",
                                                    overflow: "hidden",
                                                    // border: index === modalImageIndex ? '2px solid #FF8C00' : '1px solid #444'
                                                }}
                                            >
                                                <img
                                                    className="img-fluid"
                                                    style={{ height: "123px", width: "123px", border: index === modalImageIndex ? '2px solid #FF8C00' : '1px solid #444', borderRadius: "6px" }}
                                                    src={img}
                                                    alt={`Thumbnail ${index + 1}`}
                                                    onClick={() => modalBigSliderRef.current?.slickGoTo(index)}
                                                />
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p style={{ color: 'white', textAlign: 'center' }}>No thumbnails available</p>
                                )
                            )}
                        </Slider>
                    </div>
                </Modal>

                <div style={{ display: "none" }} className="">
                    {property?.images?.length > 0 ? (
                        <div className="main-sider">
                            <div className={viewStyles["side-left"]}>
                                <Slider
                                    {...mbslides}
                                    asNavFor={nav2}
                                    ref={(slider1) => setNav1(slider1)}
                                    dots={true}
                                >
                                    {property.images.map((slideimg, key) => (
                                        <div key={key}>
                                            <img src={slideimg} alt="property" />
                                        </div>
                                    ))}
                                </Slider>

                                <div className="mobile-edit">
                                    {user.userType === USER_TYPE.VENDOR ? (
                                        <div
                                            onClick={() =>
                                                setEditProperty({
                                                    show: true,
                                                    type: EDIT_PROPERTY_TYPE.IMAGES,
                                                })
                                            }
                                            className={viewStyles["show-all"]}
                                        >
                                            <p>
                                                <img src={pen} alt="" />
                                            </p>
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            <div className={viewStyles["side-sider"]}>
                                <div className="mobile-slide-custom">
                                    <Slider
                                        asNavFor={nav1}
                                        ref={(slider2) => setNav2(slider2)}
                                        slidesToShow={3}
                                        swipeToSlide={true}
                                        focusOnSelect={true}
                                    >
                                        <div>
                                            <img
                                                src={
                                                    property.images
                                                        ? property.images[0]
                                                        : property.imageCategories[0].categoriesImages[0]
                                                }
                                                alt="propertyView"
                                            />
                                        </div>
                                        <div>
                                            <img
                                                src={
                                                    property.images
                                                        ? property.images[1]
                                                        : property.imageCategories[0].categoriesImages[1]
                                                }
                                                alt="propertyView"
                                            />
                                        </div>
                                        <div>
                                            <img
                                                src={
                                                    property.images
                                                        ? property.images[2]
                                                        : property.imageCategories[0].categoriesImages[2]
                                                }
                                                alt="propertyView"
                                            />
                                        </div>
                                    </Slider>
                                </div>
                                {user.userType === USER_TYPE.VENDOR ? (
                                    <div
                                        onClick={() =>
                                            setEditProperty({
                                                show: true,
                                                type: EDIT_PROPERTY_TYPE.IMAGES,
                                            })
                                        }
                                        className={viewStyles["show-all"]}
                                    >
                                        <p>Edit</p>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    ) : (
                        property?.imageCategories?.map((items, index) => (
                            <div className="main-sider" key={index}>
                                <div className={viewStyles["side-left"]}>
                                    <h3
                                        className={viewStyles["side-left"]}
                                    >
                                        {imageCategories
                                            ?.filter((item) => item?._id === items?.categoriesName)
                                            ?.map((filteredItem) => filteredItem.name)[0] ||
                                            "No Name"}{" "}
                                    </h3>
                                    <Slider
                                        {...mbslides}
                                        asNavFor={nav2}
                                        ref={(slider1) => setNav1(slider1)}
                                        dots={true}
                                    >
                                        {items.categoriesImages.map((slideimg, key) => (
                                            <div key={key}>
                                                <img src={slideimg} alt="property" />
                                            </div>
                                        ))}
                                    </Slider>

                                    <div className="mobile-edit">
                                        {user.userType === USER_TYPE.VENDOR ? (
                                            <div
                                                onClick={() =>
                                                    setEditProperty({
                                                        show: true,
                                                        type: EDIT_PROPERTY_TYPE.IMAGES,
                                                    })
                                                }
                                                className={viewStyles["show-all"]}
                                            >
                                                <p>
                                                    <img src={pen} alt="" />
                                                </p>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>

                                <div className={viewStyles["side-sider"]}>
                                    <div className="mobile-slide-custom">
                                        <Slider
                                            asNavFor={nav1}
                                            ref={(slider2) => setNav2(slider2)}
                                            slidesToShow={3}
                                            swipeToSlide={true}
                                            focusOnSelect={true}
                                        >
                                            <div>
                                                <img src={items.categoriesImages[0]} alt="propertyView" />
                                            </div>
                                            <div>
                                                <img
                                                    src={
                                                        items.categoriesImages[1]
                                                            ? items.categoriesImages[1]
                                                            : items.categoriesImages[0]
                                                    }
                                                    alt="propertyView"
                                                />
                                            </div>

                                            <div>
                                                <img
                                                    src={
                                                        items.categoriesImages[2]
                                                            ? items.categoriesImages[2]
                                                            : items.categoriesImages[0]
                                                    }
                                                    alt="propertyView"
                                                />
                                            </div>
                                        </Slider>
                                    </div>
                                    {property?.imageCategories?.length - 1 == index &&
                                        user.userType === USER_TYPE.VENDOR ? (
                                        <div
                                            onClick={() =>
                                                setEditProperty({
                                                    show: true,
                                                    type: EDIT_PROPERTY_TYPE.IMAGES,
                                                    lastItem:
                                                        property.imageCategories[
                                                        property.imageCategories.length - 1
                                                        ], // Pass the last item if needed
                                                })
                                            }
                                            className={viewStyles["show-all"]}
                                        >
                                            <p>Edit</p>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        ))
                    )}
                </div>
                <div>
                    <div
                        className={`m
                             ${user.userType === USER_TYPE.VENDOR
                                ? "cursor-pointer"
                                : ""
                            }`}
                    >
                        <h4 className="property-name justify-content-between" style={{ fontFamily: "Cambay-Regular, sans-serif", display: "flex", alignItems: "center" }}>
                            <div className="d-flex align-items-center">
                                {property.name} {property.unitNumber && `[${property.unitNumber}]`}
                                <img src={pen} alt="edit" className="ms-2 cursor-pointer" style={{ width: "18px", height: "18px" }} onClick={() => handlePropertyOptionClick(EDIT_PROPERTY_TYPE.NAME)} />
                            </div>
                            {property?.currency && property?.price &&
                                <div className="d-flex align-items-center">
                                    <span>{property?.currency} {property.price?.toLocaleString()}/ night</span>
                                    <img src={pen} alt="edit" className="ms-2 cursor-pointer" style={{ width: "18px", height: "18px" }} onClick={() => handlePropertyOptionClick(EDIT_PROPERTY_TYPE.PRICE)} />
                                </div>}
                        </h4>
                        <div style={{ display: "none" }}>
                            <h5
                                className={`price d-flex align-items-center ${user.userType === USER_TYPE.VENDOR ? "cursor-pointer" : ""}`}
                                onClick={() => handlePropertyOptionClick(EDIT_PROPERTY_TYPE.PRICE)}
                                style={{ fontFamily: "Cambay-Regular, sans-serif", margin: 0 }}
                            >
                                <span className="currency me-1">{property?.currency}</span>
                                <span className="amount">{property.price?.toLocaleString()}</span>
                                {user.userType === USER_TYPE.VENDOR && (
                                    <img src={pen} alt="edit" className="ms-2" />
                                )}
                            </h5>
                        </div>
                    </div>
                    <div className="m">
                        <h5
                            className={`loc d-flex ${user.userType === USER_TYPE.VENDOR ? "cursor-pointer" : ""}location-text`}
                            onClick={() =>
                                handlePropertyOptionClick(EDIT_PROPERTY_TYPE.LOCATION)
                            }
                            style={{ fontWeight: "300", color: "#666", fontSize: "14px", fontFamily: "Cambay-Regular, sans-serif" }}
                        >
                            <img src={LocationIcon} className="me-1" alt="location" style={{ width: 14, height: 14 }} />
                            {property.location ?
                                property.location.replace(/\.,/g, " ||") :
                                `${locationDetails.building?.replace(/\.,/g, " ||")}, ${locationDetails.area?.replace(/\.,/g, " ||")}, ${locationDetails.city?.replace(/\.,/g, " ||")}`
                            }
                            {user.userType === USER_TYPE.VENDOR && (
                                <img src={pen} alt="" className="ms-2" />
                            )}
                        </h5>
                    </div>
                    <div className="mobie-detail">
                        <div
                            className={`${viewStyles["pro-featu"]} cursor-pointer d-flex align-items-center`}
                            onClick={() => handlePropertyOptionClick(EDIT_PROPERTY_TYPE.FEATURES)}
                            style={{ fontFamily: "Cambay-Regular, sans-serif" }}
                        >
                            <ul
                                className="d-flex m-0 p-0 "
                                style={{
                                    listStyle: "none",
                                    marginTop: "8px",
                                }}
                            >
                                <li style={{ marginRight: "10px" }}>{property.personCapacity || 0} Guest</li>
                                <li style={{ margin: "0 10px" }}>
                                    {property.isStudio ? "Studio" : property.bedrooms + " Bedroom"}
                                </li>
                                <li style={{ margin: "0 10px" }}>{property.bathroomsCount} Bathroom</li>
                                <li style={{ margin: "0 10px" }}>{property?.beds} Beds</li>
                            </ul>
                            <span>{user.userType === USER_TYPE.VENDOR && (
                                <img src={pen} alt="Edit" />
                            )}</span>
                        </div>
                        {windowWidth >= 767 ? (
                            <div
                                style={{
                                    height: "1px",
                                    backgroundColor: "#FF8C00",
                                    width: "100%",
                                    marginTop: "12px",
                                }}
                            ></div>) : null}

                    </div>
                    <div className="mobie-detail" style={{ display: "none" }}>
                        <h6 style={{ fontFamily: "Cambay-Regular, sans-serif" }}>Price</h6>
                        <h5
                            className={`price d-flex align-items-center ${user.userType === USER_TYPE.VENDOR ? "cursor-pointer" : ""}`}
                            onClick={() =>
                                handlePropertyOptionClick(EDIT_PROPERTY_TYPE.PRICE)
                            }
                            style={{ fontFamily: "Cambay-Regular, sans-serif" }}
                        >
                            <span className="currency me-1">{property?.currency}</span>
                            <span className="amount">{property.price?.toLocaleString()}</span>
                            {user.userType === USER_TYPE.VENDOR && (
                                <img src={pen} alt="edit" className="ms-2" />
                            )}
                        </h5>
                    </div>
                    <div className="mobie-detail">
                        <div
                            onClick={() =>
                                handlePropertyOptionClick(EDIT_PROPERTY_TYPE.DESCRIPTION)
                            }
                            className={` d-flex ${user.userType === USER_TYPE.VENDOR ? "cursor-pointer" : ""
                                }`}
                        >
                            <h6 style={{ fontFamily: "Cambay-Regular, sans-serif" }}>
                                Description <img src={pen} alt="" />
                            </h6>
                        </div>
                        <div
                            className="cursor-pointer"
                            onClick={() =>
                                handlePropertyOptionClick(EDIT_PROPERTY_TYPE.DESCRIPTION)
                            }
                            style={{ fontFamily: "Cambay-Regular, sans-serif" }}
                        >
                            {/* <div
                dangerouslySetInnerHTML={{
                  __html: mdParser.render(property.description),
                }}
              /> */}
                            {property.description.length > maxLength && !showFullDescription ? (
                                <>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: mdParser.render(
                                                property.description.slice(0, maxLength) + "..."
                                            ),
                                        }}
                                    />
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setShowFullDescription(true);
                                        }}
                                        className="btn btn-link p-0"
                                    >
                                        Read More
                                    </button>
                                </>
                            ) : (
                                <>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: mdParser.render(property.description),
                                        }}
                                    />
                                    {property.description.length > maxLength && (
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setShowFullDescription(false);
                                            }}
                                            className="btn btn-link p-0"
                                        >
                                            Read Less
                                        </button>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    <div className="mobie-detail">
                        <div
                            className={`description d-flex my-4 ${user.userType === USER_TYPE.VENDOR ? "cursor-pointer" : ""}`}
                            // onClick={() =>
                            //     handlePropertyOptionClick(EDIT_PROPERTY_TYPE.FEATURES)
                            // }
                            style={{ borderBottom: "none" }}
                        >
                            {property.hotelRooms && property.hotelRooms.length > 0 ?
                                <div className="d-flex w-100 justify-content-between">
                                    <h6 style={{ fontFamily: "Cambay-Regular, sans-serif" }}>
                                        Sub Rooms
                                    </h6>
                                    <div className="d-flex" style={{ gap: "10px" }}>
                                        <Button
                                            className={` ${viewMode === "grid" ? "activeBtn" : "InactiveBtn"}`}
                                            onClick={() => setViewMode("grid")}
                                        >
                                            <CiGrid41 />
                                        </Button>
                                        <Button
                                            className={` ${viewMode === "list" ? "activeBtn" : "InactiveBtn"}`}
                                            onClick={() => setViewMode("list")}
                                        >
                                            <CiBoxList />
                                        </Button>
                                        {/* <Button className="InactiveBtn"><IoFilterOutline /> All Rooms</Button> */}
                                        <Link to={`/property/add_room/${id}`} className="d-none d-md-flex">
                                            <Button className="InactiveBtn">Add another Rooms</Button>
                                        </Link>
                                    </div>
                                </div>
                                : <h6 style={{ fontFamily: "Cambay-Regular, sans-serif" }}>
                                    Property Feature <img src={pen} alt="" onClick={() => handlePropertyOptionClick(EDIT_PROPERTY_TYPE.FEATURES)} />
                                </h6>}
                        </div>
                        <div
                            className="cursor-pointer"
                            onClick={() => {
                                if (property.hotelRooms && property.hotelRooms.length > 0) {
                                    null
                                } else {
                                    handlePropertyOptionClick(EDIT_PROPERTY_TYPE.FEATURES);
                                }
                            }}
                            style={{ fontFamily: "Cambay-Regular, sans-serif" }}
                        >
                            {property.hotelRooms && property.hotelRooms.length > 0 ? (
                                <div className="container-fluid p-0">
                                    {viewMode === "grid" ? (
                                        <>
                                            <div className="row gy-4 gx-3">
                                                {visibleRooms.map((room, index) => (
                                                    <div key={index} className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                        <div className={`card border h-100 ${room?.isActive === false ? "disableCard" : ""}`} style={{ borderRadius: "8px", overflow: "hidden", border: "1px solid #dee2e6" }}>
                                                            <div style={{ position: "relative" }}>
                                                                {room.roomPhotos && room.roomPhotos.length > 0 ? (
                                                                    <img
                                                                        src={`https://imagedelivery.net/DFySbGARKFDYSW_EywLfqQ/${room.roomPhotos[0]}/public`}
                                                                        alt={room.roomName || `Room ${index + 1}`}
                                                                        className="card-img-top"
                                                                        style={{ height: "180px", objectFit: "cover" }}
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        src={property.images?.[0] || "placeholder.jpg"}
                                                                        alt={room.roomName || `Room ${index + 1}`}
                                                                        className="card-img-top"
                                                                        style={{ height: "180px", objectFit: "cover" }}
                                                                    />
                                                                )}
                                                                <div style={{
                                                                    position: "absolute",
                                                                    bottom: "10px",
                                                                    left: "10px",
                                                                    display: "flex",
                                                                    flexWrap: "wrap",
                                                                    gap: "5px"
                                                                }}>
                                                                    {/* <Button className="InactiveBtn btn-sm">Edit</Button> */}
                                                                    <EditRoom roomdata={room} propertyId={id} />
                                                                    <Button
                                                                        className="InactiveBtn btn-sm"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation(); // Prevent event bubbling
                                                                            handleremoveRoom(room._id);
                                                                        }}
                                                                    >
                                                                        Delete
                                                                    </Button>
                                                                    <Link
                                                                        to={`/calendar/property/${id}`}
                                                                    >
                                                                        <Button
                                                                            className="InactiveBtn btn-sm"
                                                                        >
                                                                            Calendar
                                                                        </Button>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                            <div className="card-body p-3">
                                                                <div className="d-flex justify-content-between">
                                                                    <h5 className="card-title mb-1" style={{ fontWeight: "500", fontSize: "17px" }}>
                                                                        {room?.roomName}
                                                                    </h5>
                                                                    <div className="form-check form-switch">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={room?.isActive}
                                                                            onChange={(e) => {
                                                                                e.stopPropagation();
                                                                                activeDeactiveRoom(room._id, room?.isActive);
                                                                            }}
                                                                            style={{
                                                                                cursor: "pointer",
                                                                                backgroundColor: room?.isActive ? "#FF8C00" : "#ccc",
                                                                                borderColor: room?.isActive ? "#FF8C00" : "#ccc"
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <p className="card-text mb-3" style={{ fontSize: "13px", color: "#666" }}>
                                                                    Reference No. <span style={{ color: "#e89533" }}>({property.referenceNumber})</span>
                                                                </p>
                                                                <div className="row text-center g-1">
                                                                    {[
                                                                        { label: "MAX GUESTS", value: room.maxGuests || 2 },
                                                                        { label: "MAX ADULTS", value: room.maxAdults || 2 },
                                                                        { label: "MAX CHILDREN", value: room.maxChildren || 1 },
                                                                        { label: "NO. OF THIS TYPE", value: room.numberOfRooms || 25 },
                                                                    ].map((item, index) => (
                                                                        <div key={index} className="col-6 col-sm-3 px-1 py-2 rounded" style={{ background: "#E894321A", border: "3px solid white" }}>
                                                                            <div
                                                                                style={{
                                                                                    color: "#777",
                                                                                    fontSize: "10px",
                                                                                    textTransform: "uppercase",
                                                                                    height: "25px",
                                                                                    lineHeight: "1.2"
                                                                                }}
                                                                            >
                                                                                {item.label}
                                                                            </div>
                                                                            <div
                                                                                style={{
                                                                                    fontSize: "16px",
                                                                                    fontWeight: 500,
                                                                                    marginTop: "3px"
                                                                                }}
                                                                            >
                                                                                {item.value}
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            {hasMoreRooms && (
                                                <div className="text-center mt-4 mb-3">
                                                    <Button
                                                        className="InactiveBtn"
                                                        onClick={loadMore}
                                                        disabled={isLoading}
                                                    >
                                                        {isLoading ? "Loading..." : "Load More"}
                                                    </Button>
                                                </div>
                                            )}
                                            <div className="m-4">
                                                <Link to={`/property/add_room/${id}`} className="d-flex justify-content-center d-md-none">
                                                    <Button className="InactiveBtn">Add another Rooms</Button>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div>
                                                {visibleRooms.map((room, index) => (
                                                    <div key={index} className="card mb-3" style={{ border: "1px solid rgba(0,0,0,0.1)", borderRadius: "8px", overflow: "hidden" }}>
                                                        <div className="row g-0">
                                                            <div className="card-body py-2 px-3">
                                                                <div className="d-flex justify-content-between align-items-center mt-1">
                                                                    {room.roomPhotos && room.roomPhotos.length > 0 ? (
                                                                        <img
                                                                            src={`https://imagedelivery.net/DFySbGARKFDYSW_EywLfqQ/${room.roomPhotos[0]}/public`}
                                                                            alt={room.roomName || `Room ${index + 1}`}
                                                                            className="rounded"
                                                                            style={{ width: "80px", height: "80px", objectFit: "cover" }}
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            src={property.images?.[0] || "placeholder.jpg"}
                                                                            alt={room.roomName || `Room ${index + 1}`}
                                                                            className="rounded"
                                                                            style={{ width: "80px", height: "80px", objectFit: "cover" }}
                                                                        />
                                                                    )}
                                                                    <h5 className="card-title mb-0" style={{ fontWeight: "500", fontSize: "16px" }}>
                                                                        {room?.roomName}
                                                                    </h5>
                                                                    {[
                                                                        { label: "MAX GUESTS", value: room.maxGuests || 2 },
                                                                        { label: "MAX ADULTS", value: room.maxAdults || 2 },
                                                                        { label: "MAX CHILDREN", value: room.maxChildren || 1 },
                                                                        { label: "NO. OF THIS TYPE", value: room.numberOfRooms || 25 },
                                                                    ].map((item, idx) => (
                                                                        <div key={idx} className="text-center py-1 px-3 me-3" style={{
                                                                            background: "#E894321A",
                                                                            borderRadius: "4px",
                                                                            minWidth: "80px"
                                                                        }}>
                                                                            <div
                                                                                style={{
                                                                                    color: "#777",
                                                                                    fontSize: "10px",
                                                                                    textTransform: "uppercase",
                                                                                    lineHeight: "1.2"
                                                                                }}
                                                                            >
                                                                                {item.label}
                                                                            </div>
                                                                            <div
                                                                                style={{
                                                                                    fontSize: "16px",
                                                                                    fontWeight: 500,
                                                                                    marginTop: "3px"
                                                                                }}
                                                                            >
                                                                                {item.value}
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                    <EditRoom />
                                                                    <Button
                                                                        className="InactiveBtn"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation(); // Prevent event bubbling
                                                                            handleremoveRoom(room._id);
                                                                        }}
                                                                    >
                                                                        Delete
                                                                    </Button>
                                                                    <Link
                                                                        to={`/calendar/property/${id}`}
                                                                    >
                                                                        <Button
                                                                            className="InactiveBtn"
                                                                        >
                                                                            Calendar
                                                                        </Button></Link>
                                                                    <div className="form-check form-switch">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={room?.isActive}
                                                                            onChange={(e) => {
                                                                                e.stopPropagation();
                                                                                activeDeactiveRoom(room._id, room?.isActive);
                                                                            }}
                                                                            style={{
                                                                                cursor: "pointer",
                                                                                backgroundColor: room?.isActive ? "#FF8C00" : "#ccc",
                                                                                borderColor: room?.isActive ? "#FF8C00" : "#ccc"
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="text-center m-4 ">
                                                <Link to={`/property/add_room/${id}`} className="d-flex d-md-none">
                                                    <Button className="InactiveBtn">Add another Rooms</Button>
                                                </Link>
                                            </div>
                                            {hasMoreRooms && (
                                                <div className="text-center mt-4 mb-3">
                                                    <Button
                                                        className="InactiveBtn"
                                                        onClick={loadMore}
                                                        disabled={isLoading}
                                                    >
                                                        {isLoading ? "Loading..." : "Load More"}
                                                    </Button>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            ) : (
                                <Slider
                                    slidesToShow={
                                        windowWidth < 766
                                            ? 1
                                            : propertyFeatures.bedrooms.length < 3
                                                ? propertyFeatures.bedrooms.length
                                                : 3
                                    }
                                    slidesToScroll={
                                        windowWidth < 766
                                            ? 1
                                            : propertyFeatures.bedrooms.length < 3
                                                ? propertyFeatures.bedrooms.length
                                                : 3
                                    }
                                    dots={true}
                                >
                                    {propertyFeatures.bedrooms.map((bedroom, index) => (
                                        <div
                                            key={index}
                                            className={`${styles.propertyFeature} ${propertyFeatures.bedrooms.length === 1 ? "w-25" : ""}`}
                                        >
                                            <p>Room {index + 1}</p>
                                            <ul>
                                                {bedroom.bedData.map((it, innerInd) => (
                                                    <li key={innerInd}>
                                                        <div>
                                                            <img
                                                                src={bedroom.bedIcons[innerInd].icon}
                                                                className={styles.bedIcon}
                                                                alt="double_bed"
                                                            />
                                                            {it.bedName} ({it.numberOfBeds}){" "}
                                                            {bedroom.bathRoomData ? "with Bathroom" : ""}
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                                </Slider>
                            )}
                        </div>
                    </div>
                    <div className="mobie-detail">
                        <div>
                            {propertyFeatures.nonAttachedBathrooms.length > 0 ? (
                                <p style={{ fontFamily: "Cambay-Regular, sans-serif" }}>
                                    {propertyFeatures.nonAttachedBathrooms.length} Non Attached
                                    Bathroom
                                </p>
                            ) : null}
                        </div>
                        {property.specialAmmenities.length > 0 && (
                            <Fragment>
                                <div className="description d-flex mb-2" style={{ borderBottom: "none" }}>
                                    <h6 className={styles["special-amnitiy"]} style={{ fontFamily: "Cambay-Regular, sans-serif" }}>
                                        Special Features <img src={pen} alt="" />
                                    </h6>
                                </div>
                                <ul
                                    className={`${styles.amnites} ${styles["aminity-wh-img"]} cursor-pointer`}
                                    onClick={() =>
                                        handlePropertyOptionClick(EDIT_PROPERTY_TYPE.AMMENITIES)
                                    }
                                    style={{ fontFamily: "Cambay-Regular, sans-serif" }}
                                >
                                    {property.specialAmmenities &&
                                        property.specialAmmenities.map((item, key) => (
                                            <li key={key}>
                                                <img src={item.icon} alt="" />
                                                {item.title}
                                            </li>
                                        ))}
                                </ul>
                            </Fragment>
                        )}
                    </div>
                    <div className="mobie-detail">
                        <div
                            className={`description d-flex mt-4 mb-2 ${user.userType === USER_TYPE.VENDOR ? "cursor-pointer" : ""}`}
                            onClick={() =>
                                handlePropertyOptionClick(EDIT_PROPERTY_TYPE.AMMENITIES)
                            }
                            style={{ borderBottom: "none" }}
                        >
                            <h6 style={{ fontFamily: "Cambay-Regular, sans-serif" }}>
                                Ammenities <img src={pen} alt="" />
                            </h6>
                        </div>

                        <ul
                            className={`${styles.amnites} ${styles["aminity-wh-img"]} cursor-pointer`}
                            onClick={() =>
                                handlePropertyOptionClick(EDIT_PROPERTY_TYPE.AMMENITIES)
                            }
                            style={{ fontFamily: "Cambay-Regular, sans-serif" }}
                        >
                            {property.commonAmenities &&
                                property.commonAmenities.map((item, key) => (
                                    <li key={key}>
                                        <img src={item.icon} alt="" />
                                        {item.title}
                                    </li>
                                ))}
                        </ul>
                    </div>
                    {/* <div className="mobie-detail">
                        <div
                            className={`description d-flex mb-3 ${user.userType === USER_TYPE.VENDOR ? "cursor-pointer" : ""}`}
                            onClick={() =>
                                handlePropertyOptionClick(EDIT_PROPERTY_TYPE.OTHER_ROOM)
                            }
                            style={{ borderBottom: "none" }}
                        >
                            <h6 style={{ fontFamily: "Cambay-Regular, sans-serif" }}>
                                Other Rooms <img src={pen} alt="" />
                            </h6>
                        </div>
                        <ul
                            className={`${styles.amnites} ${styles["aminity-wh-img"]} ${styles["other-room"]} cursor-pointer`}
                            onClick={() =>
                                handlePropertyOptionClick(EDIT_PROPERTY_TYPE.OTHER_ROOM)
                            }
                            style={{ fontFamily: "Cambay-Regular, sans-serif" }}
                        >
                            {property.otherRoom.driverRoom ? (
                                <li>
                                    <img
                                        alt="driverRoom"
                                        className={styles.room}
                                        src={
                                            property.otherRoom.driverRoomBathRoom
                                                ? BedroomWithBathroom
                                                : BedroomWithoutBathroom
                                        }
                                    />
                                    Driver Room{" "}
                                    {property.otherRoom.driverRoomBathRoom
                                        ? " with attached bathroom"
                                        : ""}
                                </li>
                            ) : (
                                <li>No Driver Room</li>
                            )}

                            {property.otherRoom.maidRoom ? (
                                <li>
                                    <img
                                        alt="maidRoom"
                                        className={styles.room}
                                        src={
                                            property.otherRoom.maidRoomBathRoom
                                                ? BedroomWithBathroom
                                                : BedroomWithoutBathroom
                                        }
                                    />
                                    Maid Room{" "}
                                    {property.otherRoom.maidRoomBathRoom
                                        ? " with attached bathroom"
                                        : ""}
                                </li>
                            ) : (
                                <li>No Maid Room</li>
                            )}
                        </ul>
                    </div> */}
                    <div className="description d-flex mt-4 mb-2" style={{ borderBottom: "none" }}>
                        <h4 style={{ fontFamily: "Cambay-Regular, sans-serif" }}>View on map</h4>
                    </div>
                    <div id="map-show" className={viewStyles["map"]}>
                        <iframe
                            title="Car Location"
                            height="500px"
                            width="100%"
                            src={`https://maps.google.com/maps?q=${property && property.latitude
                                },${property && property.longitude}&h1=es;&output=embed`}
                        ></iframe>
                    </div>

                    <div
                        className={`description d-flex mt-4 mb-2 ${user.userType === USER_TYPE.VENDOR ? "cursor-pointer" : ""}`}
                        onClick={() =>
                            handlePropertyOptionClick(EDIT_PROPERTY_TYPE.RECOMMENDED)
                        }
                        style={{ borderBottom: "none" }}
                    >
                    </div>
                </div>
            </div>
            <EditProperty
                {...editProperty}
                property={property}
                closeEditPropertyModal={closeEditPropertyModal}
                loader={loader}
                recommendedProperties={recommendedProperties.properties}
                propertyCount={recommendedProperties.count}
            />
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    specificProperty: (query, loader) =>
        dispatch(CommonAction.specificProperty(query, loader)),
    getRecommendedProperties: (query, loader) =>
        dispatch(CommonAction.recommendedProperties(query, loader)),
});

export default connect(null, mapDispatchToProps)(ViewProperty);
