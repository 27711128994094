import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { debounce } from "lodash";
import nsearch from "../../../assets/images/nsearch.png";
import dn from "../../../assets/images/dn.png";
import fltic from "../../../assets/images/fltic.png";
import Apartment from "../../../assets/images/Apartment.png";
import HomeOrg from "../../../assets/images/HomeOrg.png";
import Villa from "../../../assets/images/Villa.png";
import Hotel from "../../../assets/images/Hotel.png";
import { AiOutlineDelete } from "react-icons/ai";
import { Card, Row, Col, Container, Badge, Modal } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import { MdOutlineLocationOn } from "react-icons/md";
import { FiUsers } from "react-icons/fi";
import { IoBedOutline } from "react-icons/io5";
import { PiBathtub } from "react-icons/pi";
import {
    SORTING_FIELDS,
    USER_TYPE,
    CATEGORY_TYPE,
    MESSAGES,
} from "../../../constants";
import { APP_ROUTES } from "../../../routes";
import { CommonAction } from "../../../store/common/thunk";
import { AdminPropertyAction } from "../../../store/admin/thunk";
import { VendorPropertyAction } from "../../../store/vendor/thunk";
import { Button, Pagination, PropertyCarTour } from "../../../components";
import {
    FilterIcon,
    SearchIcon,
    LocationWhiteIcon,
    DeleteIcon,
    ViewIcon,
    GuestIcon,
    BedIcon,
    BathroomIcon,
} from "../../../assets/images";
import commonStyles from "../styles/common.module.css";
import AddButton from "../../../components/Button/AddButton";
import { Modal as MuiModal, Box } from '@mui/material';
import { LuHotel } from "react-icons/lu";
import { Modal as AntModal } from 'antd';
import { IoIosCloseCircleOutline, IoIosStar, IoMdAdd } from "react-icons/io";
const Properties = ({
    loader,
    propertyList,
    approveProperty,
    removeProperty,
    categoriesList,
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {
        propertiesList,
        propertiesCount,
        user,
        categories,
        loadingPropertyList,
    } = useSelector((state) => state.common);

    const [pagination, setPagination] = useState({
        offset: 0,
        limit: 6,
    });
    const [category, setCategory] = useState("");
    const [approval, setApproval] = useState("");
    const [sorting, setSorting] = useState({ type: null, order: null });
    const [mobFilter, setMobFilter] = useState(false);
    const [isApprove, setIsApprove] = useState(false);
    const [showModal, setShowModal] = useState(false);

    // useEffect(()=>{
    //   if(window.localStorage.getItem("pageName") == location?.pathname) {
    //     // setPagination({...pagination, poffset : window.localStorage.getItem("pageNo") })
    //     setPagination((prev) => {
    //       return { ...prev, offset: window.localStorage.getItem("pageNo") * prev.limit };
    //   });
    //     // window.localStorage.removeItem("pageName")
    //     // window.localStorage.removeItem("pageNo")
    //   }

    // },[])

    const getProperties = async (
        offset = location?.pathname === window.localStorage.getItem("pageName")
            ? window.localStorage.getItem("PageNo") * 6
            : pagination.offset,
        search = ""
    ) => {
        let query = {
            limit: pagination.limit,
            offset,
            sort: sorting.type,
            sortType: sorting.order,
        };

        if (search && search.length) query.searchString = search;
        if (category) query.categoryId = category;

        if (approval) query.isApproved = parseInt(approval, 10) === 1;
        propertyList(query, loader);
    };

    const switchHandler = async (property_id, isApproved) => {
        const query = { property_id, isApproved };
        approveProperty(query, loader, pagination).then((res) => {
            setIsApprove(!isApprove);
        });

        // setIsApprove(!isApprove);
    };

    const deleteProperty = async (property_id) => {
        AntModal.confirm({
            title: 'Are you sure?',
            content: 'You want to remove this property',
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                const query = { property_id };
                removeProperty(query, loader, pagination);
            }
        });
    };

    const handleCategoryChange = (e) => {
        setCategory(e.target.value.length ? e.target.value : null);
        setPagination({ offset: 0, limit: pagination.limit });
    };

    const handleApprovalChange = (e) => {
        setApproval(e.target.value.length ? e.target.value : null);
        setPagination({ offset: 0, limit: pagination.limit });
    };

    const handleSortingChange = (e) => {
        const value = e.target.value.split("_");

        if (value.length > 1) {
            setSorting({ type: value[0], order: parseInt(value[1]) === 0 ? -1 : 1 });
        } else setSorting({ type: null, value: null });
    };

    const clearResult = () => {
        setCategory("");
        setApproval("");

        getProperties(0, "");
        setSorting({ type: null, order: null });
    };

    // const debouncePropertyList = debounce(() => getProperties(0), 1000);

    useEffect(() => {
        setTimeout(() => {
            getProperties();
        }, 1000);
    }, [pagination, sorting, isApprove]);

    useEffect(() => {
        const query = { type: CATEGORY_TYPE.PROPERTY };

        categoriesList(query, loader);
    }, []);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };

    const handlePropertyTypeSelect = (type) => {
        setShowModal(false);
        if (type === 'Hotels') {
            navigate(APP_ROUTES.ADD_HOTEL);
        } else {
            navigate(APP_ROUTES.ADD_PROPERTY);
        }
    };

    return (
        <>
            <PropertyCarTour />
            <div className={commonStyles["vendor-listing"]}>
                <div className="d-flex justify-content-between mb-3 align-items-center no-margin">
                    <ul className="paginaion">
                        <li>
                            <Link to="/">Dashboard</Link>
                        </li>
                        <li className="active">
                            <Link to="/properties">Properties</Link>
                        </li>
                    </ul>

                    <span
                        className=""
                        style={{
                            fontWeight: "bolder",
                            color: "#e89532",
                            fontSize: "16px",
                        }}
                    >
                        Total Properties: {propertiesCount}
                    </span>
                </div>

                <div className={commonStyles["filter-main"]}>
                    <div className={commonStyles["filter-left"]}>
                        <div className="form-group position-relative mobFilter">
                            <span className={commonStyles["filer-iic"]}>
                                <img src={FilterIcon} alt="filter" />
                            </span>
                            <select
                                name="categories"
                                onChange={handleApprovalChange}
                                className="mx-1"
                                value={approval}
                            >
                                <option value="">Filter by Approval</option>
                                <option key="1" value="1">
                                    Approved
                                </option>
                                <option key="0" value="0">
                                    Pending
                                </option>
                            </select>
                        </div>
                        <div className="form-group position-relative mobFilter">
                            <span className={commonStyles["filer-iic"]}>
                                <img src={FilterIcon} alt="Filter" />
                            </span>
                            <select
                                name="categories"
                                onChange={handleCategoryChange}
                                className="mx-1"
                                value={category}
                            >
                                <option value="">Filter by Category</option>
                                {categories.map((category) => (
                                    <option key={category._id} value={category._id}>
                                        {category.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group position-relative mobFilter">
                            <span className={commonStyles["filer-iic"]}>
                                <img src={FilterIcon} alt="filter" />
                            </span>
                            <select
                                name="sorting"
                                onChange={handleSortingChange}
                                className="me-1"
                                value={`${sorting.type}_${sorting.order}`}
                            >
                                <option value="">Sort</option>
                                <option value={SORTING_FIELDS.NAME_ASC}>Name (ASC)</option>
                                <option value={SORTING_FIELDS.NAME_DEC}>Name (DEC)</option>
                                <option value={SORTING_FIELDS.REFERENCE_NO_ASC}>
                                    Referenco No (ASC)
                                </option>
                                <option value={SORTING_FIELDS.REFERENCE_NO_DEC}>
                                    Referenco No (DEC)
                                </option>
                                <option value={SORTING_FIELDS.UNIT_NUMBER_ASC}>
                                    Unit No (ASC)
                                </option>
                                <option value={SORTING_FIELDS.UNIT_NUMBER_DEC}>
                                    Unit No (DEC)
                                </option>
                            </select>
                        </div>
                        {/* <div className="form-group position-relative mobile-search">
              <span className={tableStyles["search-c"]}>
                <img className="desk-ic" src={SearchIcon} alt="search" />
                <img className="mob-ic" src={nsearch} alt="search" />
              </span>
              <input
                type="text"
                placeholder="Search ref number"
                onChange={(e) => setTextSearch(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") debouncePropertyList();
                }}
                value={textSearch}
              />
              {textSearch.length || category || approval || sorting.order ? (
                <button
                  className={tableStyles["search-button"]}
                  onClick={clearResult}
                >
                  <i className="fa fa-eraser" />
                </button>
              ) : null}
            </div> */}
                        <div className="propertyType">
                            <select>
                                <option>Propery Type</option>
                            </select>
                            <span>
                                <img src={dn} alt="search" />
                            </span>
                        </div>
                        <div className="FilterMob">
                            <span onClick={() => setMobFilter(!mobFilter)}>
                                <img src={fltic} alt="" />
                            </span>
                            {mobFilter && (
                                <div className="filterMobDropdown">
                                    <div className="form-group position-relative ">
                                        <span className={commonStyles["filer-iic"]}>
                                            <img src={FilterIcon} alt="filter" />
                                        </span>
                                        <select
                                            name="categories"
                                            onChange={handleApprovalChange}
                                            className="mx-1"
                                            value={approval}
                                        >
                                            <option value="">Filter by Approval</option>
                                            <option key="1" value="1">
                                                Approved
                                            </option>
                                            <option key="0" value="0">
                                                Pending
                                            </option>
                                        </select>
                                    </div>
                                    <div className="form-group position-relative ">
                                        <span className={commonStyles["filer-iic"]}>
                                            <img src={FilterIcon} alt="Filter" />
                                        </span>
                                        <select
                                            name="categories"
                                            onChange={handleCategoryChange}
                                            className="mx-1"
                                            value={category}
                                        >
                                            <option value="">Filter by Category</option>
                                            {categories.map((category) => (
                                                <option key={category._id} value={category._id}>
                                                    {category.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group position-relative ">
                                        <span className={commonStyles["filer-iic"]}>
                                            <img src={FilterIcon} alt="filter" />
                                        </span>
                                        <select
                                            name="sorting"
                                            onChange={handleSortingChange}
                                            className="me-1"
                                            value={`${sorting.type}_${sorting.order}`}
                                        >
                                            <option value="">Sort</option>
                                            <option value={SORTING_FIELDS.NAME_ASC}>
                                                Name (ASC)
                                            </option>
                                            <option value={SORTING_FIELDS.NAME_DEC}>
                                                Name (DEC)
                                            </option>
                                            <option value={SORTING_FIELDS.REFERENCE_NO_ASC}>
                                                Referenco No (ASC)
                                            </option>
                                            <option value={SORTING_FIELDS.REFERENCE_NO_DEC}>
                                                Referenco No (DEC)
                                            </option>
                                            <option value={SORTING_FIELDS.UNIT_NUMBER_ASC}>
                                                Unit No (ASC)
                                            </option>
                                            <option value={SORTING_FIELDS.UNIT_NUMBER_DEC}>
                                                Unit No (DEC)
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                    {/* Show clear button only when filters are applied */}
                    {(category || approval || sorting.type) && (
                        <div>
                            <IoIosCloseCircleOutline
                                onClick={clearResult}
                                style={{
                                    cursor: 'pointer',
                                    fontSize: '30px',
                                    color: '#e89532',
                                }}
                                title="Clear All Filters"
                            />
                        </div>
                    )}
                    <div className="">
                        {user.userType === USER_TYPE.VENDOR ? (
                            <>

                                <Button
                                    type="button"
                                    handleClick={() => setShowModal(true)}
                                    title="Add"
                                    className="mt-2 "
                                />

                                <MuiModal
                                    open={showModal}
                                    onClose={() => setShowModal(false)}
                                    aria-labelledby="modal-title"
                                    aria-describedby="modal-description"
                                >
                                    <Box
                                        sx={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            width: { xs: "90%", sm: "80%", md: "60%", lg: "500px" },
                                            bgcolor: "background.paper",
                                            borderRadius: "8px",
                                            boxShadow: 24,
                                            p: { xs: 2, md: 4 },
                                        }}
                                    >
                                        <div className="modal-header justify-content-between border-bottom pb-3 mb-3">
                                            <h5 className="modal-title" id="modal-title">
                                                Select the property you want to list
                                            </h5>
                                            <button
                                                type="button"
                                                className="btn-close"
                                                onClick={() => setShowModal(false)}
                                                aria-label="Close"
                                            />
                                        </div>
                                        <div className="modal-body">
                                            <Row className="g-4">
                                                {[
                                                    { name: "Apartment", img: Apartment },
                                                    { name: "Hotels", img: Hotel },
                                                    { name: "House", img: HomeOrg },
                                                    { name: "Villa", img: Villa },
                                                ].map((property, index) => (
                                                    <Col
                                                        key={index}
                                                        xs={6}
                                                        md={6}
                                                        className="text-center"
                                                    >
                                                        <div
                                                            className="property-type-option"
                                                            onClick={() =>
                                                                handlePropertyTypeSelect(property.name)
                                                            }
                                                            style={{
                                                                cursor: "pointer",
                                                                padding: "15px",
                                                                border: "1px solid #e0e0e0",
                                                                borderRadius: "8px",
                                                                transition: "all 0.3s ease",
                                                            }}
                                                        >
                                                            <div className="icon-wrapper mb-3 mt-1">
                                                                <img
                                                                    src={property.img}
                                                                    alt={property.name}
                                                                    width={60}
                                                                />
                                                            </div>
                                                            <p className="mb-0">{property.name}</p>
                                                        </div>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </div>
                                    </Box>
                                </MuiModal>

                                <style jsx>{`
                  .property-type-option {
                    cursor: pointer;
                    padding: 15px;
                    border: 1px solid #e0e0e0;
                    border-radius: 8px;
                    transition: all 0.3s ease;
                  }

                  .property-type-option:hover {
                    border-color: #e89532;
                    transform: translateY(-2px);
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                  }
                `}</style>
                            </>
                        ) : null}
                    </div>
                </div>

                <div className={` mobile-slider ${commonStyles["new-tab-design"]}`}>
                    <div className="row g-4">
                        {loadingPropertyList ? (
                            <div className="d-flex justify-content-center align-items-center flex-column loading-text">
                                <h5>{MESSAGES?.LOADING}</h5>
                            </div>
                        ) : null}
                        {!loadingPropertyList && propertiesList.length === 0 ? (
                            <p className="text-center">{MESSAGES?.PROPERTY?.NOT_FOUND}</p>
                        ) : null}
                        {!loadingPropertyList &&
                            propertiesList.map((property) => (
                                <Col lg={4} md={6} key={property._id}>
                                    <Link
                                        to={`/property/${property?._id}`}>
                                        <Card className="h-100 bg-transparent border-0 property-card position-relative" style={{ boxShadow: 'none' }}>
                                            {user?.userType === USER_TYPE?.VENDOR && (
                                                <button
                                                    className="btn btn-outline-danger btn-sm position-absolute"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        deleteProperty(property._id);
                                                    }}
                                                    style={{
                                                        top: "10px",
                                                        right: "10px",
                                                        zIndex: 7,
                                                        background: "white",
                                                        borderRadius: "50%",
                                                        width: "32px",
                                                        height: "32px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <AiOutlineDelete className="text-danger" />
                                                    {/* <img
                                                        src={DeleteIcon}
                                                        alt="delete"
                                                        style={{ width: "16px" }}
                                                    /> */}
                                                </button>
                                            )}

                                            <Carousel interval={2000} className="property-carousel">
                                                {property?.imageCategories?.length > 0
                                                    ? property.imageCategories[0].categoriesImages?.map(
                                                        (image, idx) => (
                                                            <Carousel.Item key={idx}>
                                                                <img
                                                                    className="d-block w-100"
                                                                    src={image}
                                                                    alt={`Property ${idx + 1}`}
                                                                    style={{
                                                                        height: "250px",
                                                                        objectFit: "cover",
                                                                    }}
                                                                />
                                                            </Carousel.Item>
                                                        )
                                                    )
                                                    : property.images.map((image, idx) => (
                                                        <Carousel.Item key={idx}>
                                                            <img
                                                                className="d-block w-100"
                                                                src={image}
                                                                alt={`Property ${idx + 1}`}
                                                                style={{
                                                                    height: "250px",
                                                                    objectFit: "cover",
                                                                    borderRadius: "15px",
                                                                }}
                                                            />
                                                        </Carousel.Item>
                                                    ))}
                                            </Carousel>

                                            <Card.Body className="px-2">
                                                <div className="d-flex justify-content-between align-items-start mb-2">
                                                    <p
                                                        className="mb-0 text-dark"
                                                        style={{
                                                            fontWeight: "700",
                                                            fontSize: "16px",
                                                            fontFamily: "Cambay-Regular, sans-serif",
                                                        }}
                                                    >
                                                        {property.name}
                                                    </p>
                                                </div>

                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <p
                                                        className="text-dark m-0"
                                                        style={{ fontSize: "14px", fontWeight: "400" }}
                                                    >
                                                        <MdOutlineLocationOn
                                                            className="me-1"
                                                            style={{ fontSize: "16px", marginLeft: "2px" }}
                                                        />
                                                        {property.location?.replace(/\.,/g, " ||")}
                                                    </p>
                                                    {user.userType === USER_TYPE.ADMIN && (
                                                        <div
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                switchHandler(
                                                                    property._id,
                                                                    !property.isApproved
                                                                );
                                                            }}
                                                            className="form-check form-switch"
                                                        >
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={property.isApproved}
                                                                onChange={() => { }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>

                                                <Row className="d-flex justify-content-between align-items-center text-dark text-center mb-3 mx-0">
                                                    {property?.categoryName !== "Hotel" ? (<>
                                                        <Col xs={3} className="px-1">
                                                            <div className="d-flex align-items-center">
                                                                <FiUsers
                                                                    style={{ fontSize: "14px", marginLeft: "2px" }}
                                                                    className="me-1"
                                                                />
                                                                <p
                                                                    className="text-dark m-0"
                                                                    style={{
                                                                        fontSize: "12px",
                                                                        fontWeight: "400",
                                                                        whiteSpace: "nowrap",
                                                                    }}
                                                                >
                                                                    {property.personCapacity} Guests
                                                                </p>
                                                            </div>
                                                        </Col>
                                                        <Col xs={3} className="px-1">
                                                            <div className="d-flex align-items-center">
                                                                <IoBedOutline
                                                                    style={{ fontSize: "14px", marginLeft: "2px" }}
                                                                    className="me-1"
                                                                />
                                                                <p
                                                                    className="text-dark m-0"
                                                                    style={{
                                                                        fontSize: "12px",
                                                                        fontWeight: "400",
                                                                        whiteSpace: "nowrap",
                                                                    }}
                                                                >
                                                                    {property.bedRoomsCount || 0} Rooms
                                                                </p>
                                                            </div>
                                                        </Col>
                                                        <Col xs={3} className="px-1">
                                                            <div className="d-flex align-items-center">
                                                                <PiBathtub
                                                                    style={{ fontSize: "14px", marginLeft: "2px" }}
                                                                    className="me-1"
                                                                />
                                                                <p
                                                                    className="text-dark m-0"
                                                                    style={{
                                                                        fontSize: "12px",
                                                                        fontWeight: "400",
                                                                        whiteSpace: "nowrap",
                                                                    }}
                                                                >
                                                                    {property.bathRoomsCount || 0} Baths
                                                                </p>
                                                            </div>
                                                        </Col>
                                                        <Col xs={3} className="px-1">
                                                            <div className="d-flex align-items-center">
                                                                <IoBedOutline
                                                                    style={{ fontSize: "14px", marginLeft: "2px" }}
                                                                    className="me-1"
                                                                />
                                                                <p
                                                                    className="text-dark m-0"
                                                                    style={{
                                                                        fontSize: "12px",
                                                                        fontWeight: "400",
                                                                        whiteSpace: "nowrap",
                                                                    }}
                                                                >
                                                                    {property.totalBeds || 0} Beds
                                                                </p>
                                                            </div>
                                                        </Col>
                                                    </>) : (<>


                                                        <Col xs={3} className="px-1">
                                                            <div className="d-flex align-items-center">
                                                                <LuHotel style={{ fontSize: "14px", marginLeft: "2px" }}
                                                                    className="me-1" />
                                                                <p
                                                                    className="text-dark m-0"
                                                                    style={{
                                                                        fontSize: "12px",
                                                                        fontWeight: "400",
                                                                        whiteSpace: "nowrap",
                                                                    }}
                                                                >
                                                                    {property.totalRooms} Total Rooms
                                                                </p>
                                                            </div>
                                                        </Col>
                                                        <Col xs={4} className="px-1">
                                                            <div className="d-flex align-items-center justify-content-end">
                                                                <div className="stars-container">
                                                                    {property.hotelRating === 1 || property.hotelRating === 6 ? (
                                                                        <span
                                                                            style={{
                                                                                fontSize: "12px",
                                                                                fontWeight: "400",
                                                                                whiteSpace: "nowrap",
                                                                                color: "#e89632"
                                                                            }}
                                                                        >
                                                                            {property.hotelRating === 6 ? "Hotel Apartment" : "Standard Hotel"}
                                                                        </span>
                                                                    ) : (
                                                                        [...Array(5)].map((_, index) => (
                                                                            <span
                                                                                key={index}
                                                                                style={{
                                                                                    color: index < property.hotelRating ? "#e89632" : "#ffffff",
                                                                                    textShadow: "0px 0px 1px #000",
                                                                                    fontSize: "14px",
                                                                                }}
                                                                            >
                                                                                <IoIosStar />
                                                                            </span>
                                                                        ))
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </>
                                                    )}
                                                </Row>
                                                {/* 
                      <Row className="g-2">
                        <Col xs={6}>
                          <small className="text-muted d-block">Reference No:</small>
                          <strong>{property.referenceNumber}</strong>
                        </Col>
                        <Col xs={6}>
                          <small className="text-muted d-block">Unit No:</small>
                          <strong>{property.unitNumber}</strong>
                        </Col>
                      </Row> */}
                                            </Card.Body>

                                            {/* <Card.Footer className="bg-white border-top-0">
                      <div className="d-flex justify-content-end align-items-center">
                        <Link 
                          to={`/property/${property._id}`}
                          className="btn btn-primary btn-sm"
                        >
                          View Details
                        </Link>
                      </div>
                    </Card.Footer> */}
                                        </Card>
                                    </Link>
                                </Col>
                            ))}
                    </div>

                    {user.userType === USER_TYPE.VENDOR ? (
                        <>
                            {/* <AddButton route={APP_ROUTES.ADD_PROPERTY} /> */}
                            <button
                                onClick={() => setShowModal(true)}
                                className="add-mob-btn"
                            ><IoMdAdd /></button>

                        </>
                    ) : null}

                    <Pagination
                        count={propertiesCount}
                        setPagination={setPagination}
                        pageSize={pagination.limit}
                    />
                </div>
            </div>
            <style jsx>{`
        .total-props-btn:hover {
          background: #000;
          cursor: default;
        }
      `}</style>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    propertyList: (query, loader) =>
        dispatch(CommonAction.propertyList(query, loader)),
    approveProperty: (params, loader, pagination) =>
        dispatch(AdminPropertyAction.approve(params, loader, pagination)),
    removeProperty: (query, loader, pagination) =>
        dispatch(VendorPropertyAction.remove(query, loader, pagination)),
    categoriesList: (query, loader) =>
        dispatch(CommonAction.categoriesList(query, loader)),
});

export default connect(null, mapDispatchToProps)(Properties);
